import React from "react";
import Layout from "../../layouts";
import { Container, Row, Col } from "react-bootstrap";
import "./index.scss";
import Modal from "react-modal";
import GetQuoteForm from "./getquote";
import data from "../../data/data.json";
import { API } from "../../http/API";
import { Helmet } from "react-helmet";

class Products extends React.Component {
  constructor(props) {
    super();
    this.state = {
      productList: [],
      pageData: {},
    };

    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  openModal() {
    this.setState({ isOpen: true });
  }

  closeModal() {
    this.setState({ isOpen: false });
  }

  componentDidMount() {
    API.get(`/products`)
      .then((response) => {
        if (response.status === 200 || response.status === 201) {
          this.setState({ productList: response.data.data });
          this.setState({ pageData: response.data.page });
        }
      })
      .catch((err) => console.log(err));
  }

  render() {
    return (
      <Layout>
        <Helmet>
          <title>{this.state.pageData?.meta_details?.title}</title>
          <meta
            name="description"
            content={this.state.pageData?.meta_details?.description}
          />
        </Helmet>
        <div
          className="page-title-area"
          style={{ backgroundImage: `url(${process.env.REACT_APP_IMAGE_BASE_URL +  this.state.pageData?.banner?.banner_image})` }}
         // style={{ backgroundImage: "url(assets/images/OurProductBanner.jpg)" }}
        >
          <Container>
            <Row>
              <Col lg="12">
                <div className="page-title-item text-center">
                  <h2 className="title" style={{ textAlign: "center" }}>
                    { this.state.pageData?.intro?.title}
                  </h2>
                  <span
                    style={{
                      textAlign: "center",
                      fontSize: "16px",
                      color: "#fff",
                    }}
                  >
                   { this.state.pageData?.intro?.subtitle}
                  </span>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        <div className="product_listing_main">
          {this.state.productList !== "" &&
            this.state.productList !== undefined &&
            this.state.productList?.map((item, index) =>
              index % 2 ? (
                <div
                  className="product_list sec_product_list"
                  key={index}
                  style={{ backgroundImage: `url(${process.env.REACT_APP_IMAGE_BASE_URL +  item.background_img})` }}
                >
                  <Container>
                    <Row>
                      <Col sm={6} xs={6} className="order-2 order-lg-1">
                        <img
                          src={
                            process.env.REACT_APP_IMAGE_BASE_URL +
                            item.featured_img
                          }
                        />
                      </Col>
                      <Col sm={6} xs={6} className="order-1 order-lg-2">
                        <div className="product_detail_box">
                          <h4>{item.sub_title}</h4>
                          <h2 className="title">{item.title}</h2>
                          <div className={`project-content`}>
                            <div
                              dangerouslySetInnerHTML={{
                                __html: `${item.short_description}`,
                              }}
                            ></div>
                          </div>
                          <button
                            className="main-btn wow fadeInLeft"
                            data-wow-duration="1s"
                            data-wow-delay=".1s"
                            onClick={this.openModal}
                          >
                            Get Quote
                          </button>
                          <a
                            className="inner_rout_btn"
                            href={`/products/${item.slug}`}
                          >
                            Learn More
                          </a>
                        </div>
                      </Col>
                    </Row>
                  </Container>
                </div>
              ) : (
                <div
                  className="product_list fir_product_list"
                  key={index}
                  style={{ backgroundImage: `url(${process.env.REACT_APP_IMAGE_BASE_URL +  item.background_img})` }}
                >
                  <Container>
                    <Row>
                      <Col sm={6} xs={6}>
                        <div className="product_detail_box">
                          <h4>{item.sub_title}</h4>
                          <h2 className="title">{item.title}</h2>
                          <div className={`project-content`}>
                            <p
                              dangerouslySetInnerHTML={{
                                __html: `${item.short_description}`,
                              }}
                            ></p>
                          </div>
                          <button
                            className="main-btn wow fadeInLeft"
                            data-wow-duration="1s"
                            data-wow-delay=".1s"
                            onClick={this.openModal}
                          >
                            Get Quote
                          </button>
                          <a
                            className="inner_rout_btn"
                            href={`/products/${item.slug}`}
                          >
                            Learn More
                          </a>
                        </div>
                      </Col>
                      <Col sm={6} xs={6}>
                        <img
                          src={
                            process.env.REACT_APP_IMAGE_BASE_URL +
                            item.featured_img
                          }
                        />
                      </Col>
                    </Row>
                  </Container>
                </div>
              )
            )}
        </div>
        <Modal isOpen={this.state.isOpen} contentLabel="Minimal Modal Example">
          <button className="close_btn" onClick={this.closeModal}>
            {" "}
            X{" "}
          </button>
          <GetQuoteForm />
        </Modal>
      </Layout>
    );
  }
}

export default Products;
