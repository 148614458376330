import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faFacebookF,
    faLinkedin,
    faInstagram,
    faYoutube
} from '@fortawesome/free-brands-svg-icons';


class SocialLink extends Component {
    render() {
        return (
            <ul>
                <li>
                    <a href="https://www.youtube.com/channel/UCVxzXkgDOy6F-SKGdcTBd4g" target="__blank">
                        <FontAwesomeIcon icon={faYoutube} />
                    </a>
                </li>

                <li>
                    <a href="https://www.instagram.com/m.i.c.robotics/" target="__blank">
                        <FontAwesomeIcon icon={faInstagram} />
                    </a>
                </li>

                <li>
                    <a href="https://ae.linkedin.com/company/mic-robotics" target="__blank">
                        <FontAwesomeIcon icon={faLinkedin} />
                    </a>
                </li>

                <li>
                    <a href="https://www.facebook.com/microbotics/" target="__blank">
                        <FontAwesomeIcon icon={faFacebookF} />
                    </a>
                </li>


            </ul>
        );
    }
}

export default SocialLink;