import React from 'react';
import { toast } from 'react-toastify';
import { Container, Row, Col } from 'react-bootstrap';
import { API } from '../../http/API';

class QuotePart extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      isDisabled: true,
      type: "getintouch",
      firstname: "",
      lastname: "",
      phone: "",
      email: "",
      message: "",
    };

    this.handleChange = this.handleChange.bind(this);
    this.validateEmail = this.validateEmail.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);

  }

  validateEmail(email) {
    const pattern = /[a-zA-Z0-9]+[\.]?([a-zA-Z0-9]+)?[\@][a-z]{3,9}[\.][a-z]{2,5}/g;
    const result = pattern.test(email);
    if (result === true) {
      this.setState({
        emailError: false,
        email: email
      })
    } else {
      this.setState({
        emailError: true
      })
    }
  }

  handleChange(e) {
    const target = e.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    this.setState({
      [name]: value
    });
    if (e.target.name === 'firstname') {
      if (e.target.value === '' || e.target.value === null) {
        this.setState({
          firstnameError: true
        })
      } else {
        this.setState({
          firstnameError: false,
          firstName: e.target.value
        })
      }
    }
    if (e.target.name === 'lastname') {
      if (e.target.value === '' || e.target.value === null) {
        this.setState({
          lastnameError: true
        })
      } else {
        this.setState({
          lastnameError: false,
          lastName: e.target.value
        })
      }
    }
    if (e.target.name === 'email') {
      this.validateEmail(e.target.value);
    }
  }

  handleSubmit(event) {

    event.preventDefault();

    const getintouchData = {
      firstName: this.state.firstName,
      lastName: this.state.lastName,
      email: this.state.email,
      phone: this.state.phone,
      message: this.state.message,
      type: this.state.type,
    }

    API.post(`/enquiries`, getintouchData)
      .then((response) => {
        toast.success("Thank you for submite quote!");
      })
      .catch((err) =>
        toast.warning("Something went wrong")
      );
  }


  render() {
    return (
      <div className="quote-area bg_cover"
        style={{ backgroundImage: "url(/assets/images/Get-in-touch.jpg)" }}
      >
        <div className="quote-overlay">
          <Container>
            <Row className="justify-content-center">
              <Col lg="12">
                <div className="section-title-2 text-center pl-25 pr-25">
                  <h2 className="title">Get In Touch</h2>
                  <p>Reach out to us for all your queries and we will get back to you as soon as possible</p>
                </div>
                {/* section title 2 */}
              </Col>
            </Row>
            {/* row */}
            <div className="quote-form">
              <form action="index" onSubmit={this.handleSubmit}>
                <Row>
                  <Col lg="6">
                    <div className="input-box mt-30">
                      <input
                        type="text"
                        name="firstname"
                        value={this.state.firstname}
                        onChange={(e) => this.handleChange(e)}
                        placeholder="Enter your first name"
                      />
                    </div>
                  </Col>
                  <Col lg="6">
                    <div className="input-box mt-30">
                      <input
                        type="text"
                        name="lastname"
                        value={this.state.lastname}
                        onChange={(e) => this.handleChange(e)}
                        placeholder="Enter your last name"
                      />
                    </div>
                  </Col>
                  <Col lg="6">
                    <div className="input-box mt-30">
                      <input
                        type="email"
                        name="email"
                        value={this.state.email}
                        onChange={(e) => this.handleChange(e)}
                        placeholder="Enter your email"
                      />
                      {this.state.firstnameError ? <span style={{ color: "red" }}>Please Enter some value</span> : ''}
                    </div>
                  </Col>
                  <Col lg="6">
                    <div className="input-box mt-30">
                      <input
                        type="text"
                        name="phone"
                        value={this.state.phone}
                        onChange={(e) => this.handleChange(e)}
                        placeholder="Enter your phone number"
                      />
                    </div>
                  </Col>
                  <Col lg="12">
                    <div className="input-box text-center mt-30">
                      <textarea
                        name="message"
                        value={this.state.message}
                        onChange={(e) => this.handleChange(e)}
                        id="index"
                        cols={30}
                        rows={10}
                        placeholder="Enter your message"
                        defaultValue={""}
                      />
                      <button
                        className="main-btn wow fadeInUp"
                        data-wow-duration="1s"
                        data-wow-delay=".3s"
                        type="submit"
                        onSubmit={this.handleSubmit}
                      >
                        Submit
                      </button>
                    </div>
                  </Col>
                </Row>
              </form>
            </div>
            {/* quote form */}
          </Container>
          {/* container */}
        </div>
      </div>
    );
  }
}

export default QuotePart;