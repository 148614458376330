import React from 'react';
import { Row, Col, Container } from 'react-bootstrap';
import Slider from "react-slick";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faAngleLeft,
  faAngleRight,
} from '@fortawesome/free-solid-svg-icons';

function NextArrow(props) {
  const { style, onClick } = props;
  return (
    <span onClick={onClick} className="next slick-arrow" style={style}>
      <FontAwesomeIcon icon={faAngleRight} />
    </span>
  );
}

function PrevArrow(props) {
  const { style, onClick } = props;
  return (
    <span onClick={onClick} className="prev slick-arrow" style={style}>
      <FontAwesomeIcon icon={faAngleLeft} />
    </span>
  );
}

class Banner2 extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      Banner: this.props.sliderSection,
    };
  }

  render() {
    var settings = {
      autoplaySpeed: 5000,
      // fade: true,
      arrows: true,
      initialstate: 0,
      initialSlide: 1,
      activeSlide: 1,
      dots: true,
      draggable: true,
      slidesToScroll: 1,
      autoplay: true,
      nextArrow: <NextArrow />,
      prevArrow: <PrevArrow />,
      responsive: [
        {
          breakpoint: 1330,
          settings: {
            arrows: true,
            initialstate: 0,
          }
        }
      ],
      //  className: 'slick-slider-fade',
    };
    return (
      <>
        <Slider className="banner-active banner-area"  {...settings}>
          {
            this.props.sliderSection?.map((item, index) => (
              <div className="banner-overlay" key={index}>
                <Container className="position-relative">
                  <Row className="row">
                    <Col lg="12">
                      <div className="banner-content">
                        <h1
                          className="title wow fadeInLeft"
                          data-wow-duration="2s"
                          data-wow-delay=".5s"
                          style={{ visibility: 'visible', animationDuration: '2s', animationDelay: '0.5s', animationName: 'fadeInLeft' }}
                        >
                          {item.title}
                        </h1>
                        <span
                          className="wow fadeInLeft"
                          data-wow-duration="1s"
                          data-wow-delay=".1s"
                          style={{ visibility: 'visible', animationDuration: '1s', animationDelay: '0.1s', animationName: 'fadeInLeft' }}
                        >
                          {item.subtitle}
                        </span>
                      </div>
                    </Col>
                    <Col lg="12">
                      <div className="banner-img">
                        <img src={process.env.REACT_APP_IMAGE_BASE_URL + item.background_image} alt="" />
                      </div>
                    </Col>
                  </Row>
                </Container>
              </div>

            ))
          }
        </Slider>
      </>
    );
  }
}

export default Banner2;