import React from 'react';
import { Link } from "react-router-dom";
import { Nav } from 'react-bootstrap';
import DropDown from './Dropdown';


class PartialNav extends React.Component {
  constructor() {
    super()
    this.state = {
    }

  }

  render() {
    return (
      <>
        <Nav className="mr-auto">
          <li className="nav-item sub-drop">
            <Link className="nav-link" to={'/Products'}>Products</Link>
            <DropDown />
          </li>

          <li className="nav-item">
            <Link className="nav-link" to={'/smartsolutions'}>Smart Solution</Link>
          </li>

          <li className="nav-item">
            <Link className="nav-link" to={'/Partner'}>Our Client</Link>
          </li>

          <li className="nav-item">
            <Link className="nav-link" to={'/business-cooperation'}>Business Cooperation</Link>
          </li>

          <li className="nav-item">
            <Link className="nav-link" to={'/about'}>About</Link>
          </li>

          <li className="nav-item">
            <Link className="nav-link" to={'/news'}>News</Link>
          </li>

          <li className="nav-item">
            <Link className="nav-link" to={'/contact'}>Contact</Link>
          </li>

        </Nav>
      </>
    );
  }
}

export default PartialNav;