import React from 'react';
import Layout from '../../layouts';
import { Link } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap';
import data from '../../data/data.json';
import { API } from '../../http/API';
import { Helmet } from "react-helmet";
import LeadershipPart from '../../componets/LeadershipPart/Index';
import Scenarios from '../../componets/Scenarios/Index';

class TeamPage extends React.Component {

  constructor(props) {
    super()
    this.state = {
      teamList: [],
      pagData: [],
    }
  }

  componentDidMount() {

    API.get(`/pages`)
      .then((response) => {
        if (response.status === 200 || response.status === 201) {
          let currentPage = response.data.data.find(
            (x) => x.slug === "our-client"
          );
          this.setState({ pagData: currentPage });
        }
      }).catch((err) => console.log(err));

    API.get(`/clients`)
      .then((response) => {
        if (response.status === 200 || response.status === 201) {
          this.setState({ teamList: response.data.data });
        }
      })
      .catch((err) => console.log(err));
  }

  render() {
    return (
      <Layout>
        <Helmet>
          <title>{this.state.pagData?.meta_details?.meta_title}</title>
          <meta name="description" content={this.state.pagData?.meta_details?.meta_description} />
        </Helmet>
        <div className="page-title-area" style={{ backgroundImage: 'url(assets/images/Our-Client-Banner.jpg)' }}>
          <Row>
            <Col lg="8">
              <div className="page-title-item text-center" style={{paddingLeft: '10%'}}>
                <h2 className="title">{data.teamData.title}</h2>
                <span style={{ color: '#fff', float: 'left' }}>{data.teamData.description}</span>
              </div>
            </Col>
          </Row>
        </div>
        <div>
          <div className="padding gray-bg pt-70">
            <LeadershipPart />
            <Scenarios />
          </div>
        </div>
      </Layout>
    );
  }
}

export default TeamPage;