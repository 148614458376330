import React from 'react';
import { toast } from 'react-toastify';
import { Row, Col } from 'react-bootstrap';
import './index.scss';
import { API } from '../../http/API';

class GetQuoteForm extends React.Component {

  constructor() {
    super()
    this.state = {
      isOpen: false,
      type: "quote",
      name: "",
      phone: "",
      email: "",
      message: "",
    }
    this.openModal = this.openModal.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.validateEmail = this.validateEmail.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }
  openModal = event => {
    this.setState({ isOpen: true });
    event.preventDefault();
  }


  validateEmail(email) {
    const pattern = /[a-zA-Z0-9]+[\.]?([a-zA-Z0-9]+)?[\@][a-z]{3,9}[\.][a-z]{2,5}/g;
    const result = pattern.test(email);
    if (result === true) {
      this.setState({
        emailError: false,
        email: email
      })
    } else {
      this.setState({
        emailError: true
      })
    }
  }

  handleChange(e) {
    const target = e.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    this.setState({
      [name]: value
    });
    if (e.target.name === 'firstname') {
      if (e.target.value === '' || e.target.value === null) {
        this.setState({
          firstnameError: true
        })
      } else {
        this.setState({
          firstnameError: false,
          firstName: e.target.value
        })
      }
    }
    if (e.target.name === 'lastname') {
      if (e.target.value === '' || e.target.value === null) {
        this.setState({
          lastnameError: true
        })
      } else {
        this.setState({
          lastnameError: false,
          lastName: e.target.value
        })
      }
    }
    if (e.target.name === 'email') {
      this.validateEmail(e.target.value);
    }
  }

  handleSubmit(event) {

    event.preventDefault();

    const getintouchData = {
      firstName: this.state.firstName,
      lastName: this.state.lastName,
      email: this.state.email,
      phone: this.state.phone,
      message: this.state.message,
      type: this.state.type,
    }

    API.post(`/enquiries`, getintouchData)
      .then((response) => {
        toast.success("Thank you for submite quote!");
      })
      .catch((err) =>
        toast.warning("Something went wrong")
      );
  }

  render() {
    return (
      <div className="form-area">
        <form id="enquire-form" method="post" onSubmit={this.handleSubmit}>
          <Row>
            <Col lg="12">
              <div className="input-box mt-45">
                <input
                  type="text"
                  name="name"
                  placeholder="Enter your Name"
                  onChange={(e) => this.handleChange(e)}
                  value={this.state.name}
                />
              </div>
            </Col>
            <Col lg="6">
              <div className="input-box mt-20">
                <input
                  type="text"
                  name="phone"
                  placeholder="Enter your Phone Number"
                  onChange={(e) => this.handleChange(e)}
                  value={this.state.phone}
                />
              </div>
            </Col>
            <Col lg="6">
              <div className="input-box mt-20">
                <input
                  type="email"
                  name="email"
                  placeholder="Enter your Email"
                  onChange={(e) => this.handleChange(e)}
                  value={this.state.email}
                />
              </div>
            </Col>
            <Col lg="12">
              <div className="input-box mt-20">
                <textarea
                  name="message"
                  id="index"
                  cols={30}
                  rows={10}
                  defaultValue={""}
                  placeholder="Message"
                  onChange={(e) => this.handleChange(e)}
                  value={this.state.message}
                />
              </div>
            </Col>
            <Col lg="12">
              <button className="main-btn" type="submit" onSubmit={this.handleSubmit}>
                Submit
              </button>
            </Col>
          </Row>
        </form>
        <p className="form-message" />
      </div>
    );
  }
}

export default GetQuoteForm;