import React from 'react';
import Layout from '../../layouts';
import { Container, Row, Col } from 'react-bootstrap';

class PrivacyPolicy extends React.Component {
    render() {
        return (
            <Layout>
                <div className="page-title-area">
                    <Container>
                        <Row>
                            <Col lg="12">
                                <div className="page-title-item text-center">
                                    <h2 className="title" style={{ textAlign: 'center', color: 'black' }}>Privacy Policy</h2>
                                </div>
                                {/* page title */}
                            </Col>
                        </Row>
                        {/* row */}
                    </Container>
                    {/* container */}
                </div>
                {
                    /*====== PAGE TITLE PART ENDS ======*/
                }
                <div className="pt-10 pb-160">
                    <Container>
                        <Row>
                            <Col lg="12">
                                <div className="content-area">
                                    <p>MIC Robotics Trading LLC ("us", "we", or "our") operates the www.mic-robotics.com website (the "Service").</p>
                                    <p>&nbsp;</p>
                                    <p>This page informs you of our policies regarding the collection, use, and disclosure of personal data when you use our Service and the choices you have associated with that data.</p>
                                    <p>&nbsp;</p>
                                    <p>We use your data to provide and improve the Service. By using the Service, you agree to the collection and use of information in accordance with this policy.</p>
                                    <p>&nbsp;</p>
                                    <p><strong>Information Collection and Use</strong></p>
                                    <p>&nbsp;</p>
                                    <p>We collect several different types of information for various purposes to provide and improve our Service to you.</p>
                                    <p>&nbsp;</p>
                                    <p><strong>Types of Data Collected</strong></p>
                                    <p>&nbsp;</p>
                                    <p><strong>Personal Data</strong></p>
                                    <p>&nbsp;</p>
                                    <p>While using our Service, we may ask you to provide us with certain personally identifiable information that can be used to contact or identify you ("Personal Data"). Personally identifiable information may include, but is not limited to:</p>
                                    <p>&nbsp;</p>
                                    <p>Email address</p>
                                    <p>First name and last name</p>
                                    <p>Phone number</p>
                                    <p>Address, State, Province, ZIP/Postal code, City</p>
                                    <p>Cookies and Usage Data</p>
                                    <p>Usage Data</p>
                                    <p>&nbsp;</p>
                                    <p>We may also collect information on how the Service is accessed and used ("Usage Data"). This Usage Data may include information such as your computer's Internet Protocol address (e.g. IP address), browser type, browser version, the pages of our Service that you visit, the time and date of your visit, the time spent on those pages, unique device identifiers, and other diagnostic data.</p>
                                    <p>&nbsp;</p>
                                    <p><strong>Tracking &amp; Cookies Data</strong></p>
                                    <p>&nbsp;</p>
                                    <p>We use cookies and similar tracking technologies to track the activity on our Service and hold certain information.</p>
                                    <p>&nbsp;</p>
                                    <p>Examples of Cookies we use:</p>
                                    <p>&nbsp;</p>
                                    <p>Session Cookies. We use Session Cookies to operate our Service.</p>
                                    <p>Preference Cookies. We use Preference Cookies to remember your preferences and various settings.</p>
                                    <p>Security Cookies. We use Security Cookies for security purposes.</p>
                                    <p>Use of Data</p>
                                    <p>&nbsp;</p>
                                    <p>MIC Robotics Trading LLC uses the collected data for various purposes:</p>
                                    <p>&nbsp;</p>
                                    <p>To provide and maintain the Service</p>
                                    <p>To notify you about changes to our Service</p>
                                    <p>To allow you to participate in interactive features of our Service when you choose to do so</p>
                                    <p>To provide customer care and support</p>
                                    <p>To provide analysis or valuable information so that we can improve the Service</p>
                                    <p>To monitor the usage of the Service</p>
                                    <p>To detect, prevent and address technical issues</p>
                                    <p>Transfer of Data</p>
                                    <p>&nbsp;</p>
                                    <p>Your information, including Personal Data, may be transferred to &mdash; and maintained on &mdash; computers located outside of your state, province, country or other governmental jurisdiction where the data protection laws may differ than those from your jurisdiction.</p>
                                    <p>&nbsp;</p>
                                    <p>If you are located outside United Arab Emirates and choose to provide information to us, please note that we transfer the data, including Personal Data, to United Arab Emirates and process it there.</p>
                                    <p>&nbsp;</p>
                                    <p>Your consent to this Privacy Policy followed by your submission of such information represents your agreement to that transfer.</p>
                                    <p>&nbsp;</p>
                                    <p>MIC Robotics Trading LLC will take all steps reasonably necessary to ensure that your data is treated securely and in accordance with this Privacy Policy and no transfer of your Personal Data will take place to an organization or a country unless there are adequate controls in place including the security of your data and other personal information.</p>
                                    <p>&nbsp;</p>
                                    <p><strong>Disclosure of Data</strong></p>
                                    <p>Legal Requirements</p>
                                    <p>&nbsp;</p>
                                    <p>MIC Robotics Trading LLC may disclose your Personal Data in the good faith belief that such action is necessary to:</p>
                                    <p>To comply with a legal obligation</p>
                                    <p>To protect and defend the rights or property of MIC Robotics Trading LLC</p>
                                    <p>To prevent or investigate possible wrongdoing in connection with the Service</p>
                                    <p>To protect the personal safety of users of the Service or the public</p>
                                    <p>To protect against legal liability</p>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </Layout>
        );
    }
}

export default PrivacyPolicy;