import React from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {} from "@fortawesome/free-solid-svg-icons";
import ModalVideo from "react-modal-video";
// import { faVideo } from "@fortawesome/free-solid-svg-icons";
import { faPlay } from "@fortawesome/free-solid-svg-icons";
import { Container, Row, Col } from "react-bootstrap";

class AboutPart extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      aboutSectionData: {},
      isOpen: false,
    };
    this.openModal = this.openModal.bind(this);
  }

  openModal(e) {
    e.preventDefault();
    this.setState({ isOpen: true });
  }
  componentDidMount() {
    this.setState({ aboutSectionData: this.props.aboutSection });
  }

  render() {
    return (
      <React.Fragment>
        <div className="about-area pt-90 pb-120">
          <Container>
            <Row className="align-items-center">
              <ModalVideo
                channel="youtube"
                isOpen={this.state.isOpen}
                videoId={
                  this.props.aboutSection?.introSection?.video_link.split(
                    "/"
                  )[3]
                }
                onClose={() => this.setState({ isOpen: false })}
              />
              <Col lg="6" md="12" className="order-2 order-lg-1">
                <div className="about-thumb intro-thumb mt-30">
                  <img
                    src={
                      process.env.REACT_APP_IMAGE_BASE_URL +
                      this.props.aboutSection?.introSection?.video_image
                    }
                    alt={this.props.aboutSection?.introSection?.title}
                  />
                  <Link
                    className="video-popup"
                    to={this.props.aboutSection?.introSection?.video_link}
                    onClick={this.openModal}
                  >
                    <FontAwesomeIcon
                      icon={faPlay}
                      size={`3x`}
                      className={`pl-2 pt-3`}
                    />
                  </Link>
                </div>
                {/* about thumb */}
              </Col>
              <Col lg="6" md="12" className="order-1 order-lg-2">
                <div className="about-item">
                  <span>{this.props.aboutSection?.introSection?.subtitle}</span>
                  <h3 className="title">
                    {this.props.aboutSection?.introSection?.title}
                  </h3>
                  <p
                    dangerouslySetInnerHTML={{
                      __html:
                        this.props.aboutSection?.introSection?.description,
                    }}
                  />
                  <div className="about-experience">
                    <h3></h3>
                  </div>
                  <ul>
                    <li>
                      <Link
                        className="main-btn main-btn-2 wow fadeInUp"
                        data-wow-duration="1s"
                        data-wow-delay=".1s"
                        to={"about"}
                      >
                        Read More
                      </Link>
                    </li>
                    <li>
                      <Link
                        className="main-btn wow fadeInUp"
                        data-wow-duration="2s"
                        data-wow-delay=".5s"
                        to={this.props.aboutSection?.introSection?.video_link}
                        onClick={this.openModal}
                      >
                        Watch Video
                      </Link>
                    </li>
                  </ul>
                </div>
                {/* about item */}
              </Col>
            </Row>
            {/* row */}
          </Container>
          {/* container */}
        </div>
      </React.Fragment>
    );
  }
}

export default AboutPart;
