import React from 'react';

import Footer from '../../layouts/partials/footer/index';
import Header from '../../layouts/partials/header/Header2';
import Banner2 from '../../componets/banner/Index2';
import AboutPart from '../../componets/aboutPart/Index';
// import WhatWeDoPart from '../../componets/whatWeDoPart/Index';
import WhoWeAreArea from '../../componets/whoWeAreArea/Index';
import IntroVideoPart from '../../componets/introVideoPart/Index';
import LeadershipPart from '../../componets/LeadershipPart/Index';
import QuotePart from '../../componets/quotePart/Index';
import LatestNewsPart from '../../componets/latestNewsPart/Index';
import { API } from '../../http/API';
import { Helmet } from "react-helmet";


class Index2 extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            homeData: [],
            pagData: [],
            teamList: [],
        };
    }

    componentDidMount() {

        API.get(`/pages`)
            .then((response) => {
                if (response.status === 200 || response.status === 201) {
                    let currentPage = response.data.data.find(
                        (x) => x.slug === "home-page"
                    );
                    this.setState({ pagData: currentPage });

                    API.get(`/all-sections/${currentPage._id}`)
                        .then((res) => {
                            let content = res.data.data[res.data.data.length - 1].content;
                            this.setState({ homeData: content });
                        })
                        .catch((err) => console.log(err));
                }
            })
            .catch((err) => console.log(err));

    }

    render() {
        return (
            <>
                <Helmet>
                    <title>{this.state.pagData?.meta_details?.meta_title}</title>
                    <meta name="description" content={this.state.pagData?.meta_details?.meta_description} />
                </Helmet>
                <Header />
                <Banner2
                    sliderSection={this.state.homeData.sliderSection}
                />
                <AboutPart
                    aboutSection={this.state.homeData}
                />
                <WhoWeAreArea
                    whoWeAreSection={this.state.homeData}
                />
                <IntroVideoPart
                    smartSolutionSection={this.state.homeData}
                />
                <LeadershipPart />
                <LatestNewsPart />
                <QuotePart />
                <Footer />
            </>
        );
    }
}

export default Index2;