import React from "react";
import { Link } from "react-router-dom";
import "./Index.scss";
import { Container, Row, Col } from "react-bootstrap";

class Index extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      aboutSectionData: {},
    };
  }
  componentDidMount() {
    this.setState({ aboutSectionData: this.props.introSection });
  }

  render() {
    return (
      <div className="about-experience-area pb-120">
        <Container>
          <Row>
            <Col lg="5">
              <div className="experience-item">
                <span>{this.props.introSection?.intro?.subtitle}</span>
                <h2 className="title">
                  {this.props.introSection?.intro?.title}
                </h2>
              </div>
              {/* experience item */}
            </Col>
            <Col lg="6" className="offset-lg-1">
              <div
                className="experience-item"
                dangerouslySetInnerHTML={{
                  __html: this.props.introSection?.intro?.content,
                }}
              ></div>
              {/* experience item */}
            </Col>
          </Row>
          <Row className="justify-content-center">
            <div
              className="col-lg-6 col-md-6 col-sm-6 wow fadeInUp"
              data-wow-duration=".5s"
              data-wow-delay=".2s"
            >
              <div className="single-experience mt-30">
                <img
                  src={
                    process.env.REACT_APP_IMAGE_BASE_URL +
                    this.props.introSection?.ourVision?.section_image
                  }
                  alt=""
                />
                <div className="experience-overlay">
                  <h5 className="title">
                    {this.props.introSection?.ourVision?.title}
                  </h5>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: this.props.introSection?.ourVision?.content,
                    }}
                  ></div>
                  {/* <Link to={"about"}>Read More</Link> */}
                </div>
              </div>
              {/* single experience */}
            </div>
            <div
              className="col-lg-6 col-md-6 col-sm-6 wow fadeInUp"
              data-wow-duration="1s"
              data-wow-delay=".4s"
            >
              <div className="single-experience mt-30">
                <img
                  src={
                    process.env.REACT_APP_IMAGE_BASE_URL +
                    this.props.introSection?.ourMission?.section_image
                  }
                  alt=""
                />
                <div className="experience-overlay">
                  <h5 className="title">
                    {this.props.introSection?.ourMission?.title}
                  </h5>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: this.props.introSection?.ourMission?.content,
                    }}
                  ></div>
                  {/* <Link to="/home-1">Read More</Link> */}
                </div>
              </div>
              {/* single experience */}
            </div>
          </Row>
          {/* row */}
        </Container>
        {/* container */}
      </div>
    );
  }
}

export default Index;
