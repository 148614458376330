import React from "react";
// import { Link } from 'react-router-dom';
import Layout from "../../layouts";
import { Container, Row, Col } from "react-bootstrap";
import "./index.scss";
import ModalVideo from "react-modal-video";
import { API } from "../../http/API";
import { Helmet } from "react-helmet";

class SmartSolutionsPage extends React.Component {
  constructor() {
    super();
    this.state = {
      isOpen: false,
      smartSolutionBannerData: {},
      smartSolutionData: [],
      videoId: "",
    };
    // this.openModal = this.openModal.bind(this);
    this.videoOpen = this.videoOpen.bind(this);
  }
  // openModal(e) {
  //   e.preventDefault();
  //   this.setState({ isOpen: true })
  // }

  videoOpen = (url) => {
    this.setState({ videoId: url });
    this.setState({ isOpen: true });
  };

  componentDidMount() {
    API.get(`/pages`)
      .then((response) => {
        if (response.status === 200 || response.status === 201) {
          let currentPage = response.data.data.find(
            (x) => x.slug === "smart-solution"
          );
          this.setState({ pagData: currentPage });

          API.get(`/all-sections/${currentPage._id}`)
            .then((res) => {
              let content = res.data.data[res.data.data.length - 1].content;
              this.setState({ smartSolutionBannerData: content });
            })
            .catch((err) => console.log(err));
        }
      })
      .catch((err) => console.log(err));

    API.get(`/solutions`)
      .then((response) => {
        if (response.status === 200 || response.status === 201) {
          this.setState({ smartSolutionData: response.data.data });
        }
      })
      .catch((err) => console.log(err));
  }

  render() {
    return (
      <>
        {this.state.smartSolutionData !== "" && (
          <Layout>
            <Helmet>
              <title>{this.state.pagData?.meta_details?.meta_title}</title>
              <meta
                name="description"
                content={this.state.pagData?.meta_details?.meta_description}
              />
            </Helmet>
            <div
              className="page-title-area"
              style={{
                backgroundImage: `url(${
                  process.env.REACT_APP_IMAGE_BASE_URL +
                  this.state.smartSolutionBannerData?.banner?.banner_image
                })`,
              }}
            >
              <Container>
                <Row>
                  <Col lg="8">
                    <div className="page-title-item">
                      <div className="div_overlay">
                        <h2
                          className="title"
                          style={{ color: "white", marginBottom: "20px" }}
                        >
                          {this.state.smartSolutionBannerData?.banner?.name}
                        </h2>
                        <span style={{ fontSize: "16px", color: "white" }}>
                          {
                            this.state.smartSolutionBannerData?.banner
                              ?.sub_title
                          }
                        </span>
                      </div>
                    </div>
                  </Col>
                </Row>
              </Container>
            </div>
            {/*====== PAGE TITLE PART ENDS ======*/}
            <div className="Smart_solutionsPage_listing_main">
              <ModalVideo
                channel="youtube"
                autoPlay={"autoplay"}
                isOpen={this.state.isOpen}
                videoId={this.state.videoId}
                onClose={() => this.setState({ isOpen: false })}
              />
              {this.state.smartSolutionData?.map((item, index) => (
                <div
                  className="Smart_solutionsPage_list"
                  key={index}
                  style={{
                    backgroundImage: `url(${
                      process.env.REACT_APP_IMAGE_BASE_URL +
                      item.backgroundImage
                    })`,
                  }}
                >
                  <Container>
                    <Row>
                      <Col sm={12}>
                        <div className="product_detail_box">
                          <h4>{item.sub_title}</h4>
                          <h2 className="title">{item.title}</h2>
                          <div className={`project-content`}>
                            <p
                              dangerouslySetInnerHTML={{
                                __html: `${item.description}`,
                              }}
                            ></p>
                          </div>
                          {/* <Link className="video-popup" to={item.youtubeVideoUrl} onClick={this.openModal}>Watch how it’s done</Link> */}
                          <button
                            className="video-popup"
                            onClick={() =>
                              this.videoOpen(
                                item.youtubeVideoUrl.split("/watch?v=")[1]
                              )
                            }
                          >
                            Watch how it’s done
                          </button>
                        </div>
                      </Col>
                    </Row>
                  </Container>
                </div>
              ))}
            </div>
          </Layout>
        )}
      </>
    );
  }
}

export default SmartSolutionsPage;
