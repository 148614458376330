import React from "react";
// import { Link } from 'react-router-dom';
import { Container, Row, Col } from "react-bootstrap";
import { API } from "../../http/API";
import ModalVideo from "react-modal-video";
import { faPlay } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {} from "@fortawesome/free-solid-svg-icons";

class Scenarios extends React.Component {
  constructor(props) {
    super();
    this.state = {
      scenariosList: [],
      now: new Date(),
      isOpen: false,
      videoId: "",
    };
    this.openModal = this.openModal.bind(this);
  }

  openModal = (url) => {
    this.setState({ isOpen: true });
    this.setState({ videoId: url });
  };

  componentDidMount() {
    API.get(`/scenarios`)
      .then((response) => {
        if (response.status === 200 || response.status === 201) {
          this.setState({ scenariosList: response.data.data });
        }
      })
      .catch((err) => console.log(err));
  }

  render() {
    return (
      <div className="white-bg scenerios-section">
        <Container>
          <Row className="justify-content-center">
            <div className="col-lg-8 col-md-10">
              <div className="section-title-2 text-center pl-25 pr-25">
                <h2 className="title">Use Scenarios</h2>
                <p style={{ color: "#2385C5" }}>
                  Check out our applications for the bots.
                </p>
              </div>
            </div>
          </Row>
        </Container>
        <div className="letast-news-grid">
          <Container>
            <Row>
              <Col lg="12">
                <div className="latest-news">
                  <Row>
                    <ModalVideo
                      channel="youtube"
                      autoplay
                      isOpen={this.state.isOpen}
                      videoId={this.state.videoId}
                      onClose={() => this.setState({ isOpen: false })}
                    />
                    {this.state.scenariosList?.map((item, index) => (
                      <div
                        className="col-lg-4 col-md-6 wow slideInUp"
                        data-wow-duration=".5s"
                        data-wow-delay="0s"
                        key={index}
                      >
                        <div className="letest-news-item mt-30">
                          <div className="scenarios-thumb intro-thumb mt-30">
                            <img
                              src={
                                process.env.REACT_APP_IMAGE_BASE_URL +
                                item.featured_img
                              }
                              alt={item.title}
                            />
                            <button
                              className="video-popup"
                              onClick={() =>
                                this.openModal(item.video_url?.split("/")[3])
                              }
                            >
                              <FontAwesomeIcon
                                icon={faPlay}
                                size={`3x`}
                                className={`pl-2 pt-3`}
                              />
                            </button>
                          </div>
                          <p
                            className="title"
                            style={{
                              fontSize: "16px",
                              textAlign: "center",
                              color: "#333",
                              padding: "10px 0",
                            }}
                          >
                            {item.title}
                          </p>
                        </div>
                      </div>
                    ))}
                  </Row>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    );
  }
}

export default Scenarios;
