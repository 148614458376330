import React from 'react';
import PartialNav from './PartialNav';
import { Link } from "react-router-dom";
import SocialLink from './SocialLink';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faEnvelope,
  faPhone,
} from '@fortawesome/free-solid-svg-icons';
import Logo from '../../../assets/images/MIC-Logo.png';
import { Row, Col, Navbar } from 'react-bootstrap';
import Modal from 'react-modal';
import { toast } from 'react-toastify';
import { API } from '../../../http/API';
import { StickyContainer, Sticky } from 'react-sticky';


class Header extends React.Component {

  constructor() {
    super()
    this.state = {
      isOpen: false,
      type: "enquiry",
      name: "",
      phone: "",
      email: "",
      message: "",
      isSticky: false,
    }

    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.validateEmail = this.validateEmail.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);

  }

  openModal() {
    this.setState({ isOpen: true })
  }
  closeModal() {
    this.setState({ isOpen: false })
  }

  validateEmail(email) {
    const pattern = /[a-zA-Z0-9]+[\.]?([a-zA-Z0-9]+)?[\@][a-z]{3,9}[\.][a-z]{2,5}/g;
    const result = pattern.test(email);
    if (result === true) {
      this.setState({
        emailError: false,
        email: email
      })
    } else {
      this.setState({
        emailError: true
      })
    }
  }

  handleChange(e) {
    const target = e.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    this.setState({
      [name]: value
    });
    if (e.target.name === 'name') {
      if (e.target.value === '' || e.target.value === null) {
        this.setState({
          nameError: true
        })
      } else {
        this.setState({
          nameError: false,
          Name: e.target.value
        })
      }
    }
    if (e.target.name === 'email') {
      this.validateEmail(e.target.value);
    }
  }

  handleSubmit(event) {

    event.preventDefault();

    const enquiryData = {
      name: this.state.name,
      email: this.state.email,
      phone: this.state.phone,
      message: this.state.message,
      type: this.state.type,
    }

    API.post(`/enquiries`, enquiryData)
      .then((response) => {
        toast.success("Thank you for submite quote!");
        this.setState({ isOpen: false })
      })
      .catch((err) =>
        toast.warning("Something went wrong"),
        this.setState({ isOpen: false })
      );
  }

  componentDidUpdate = () => {
    let elementId = document.getElementById("navbar");
    document.addEventListener("scroll", () => {
      if (window.scrollY > 170) {
        elementId.classList.add("is-sticky");
      } else {
        elementId.classList.remove("is-sticky");
      }
    });
    // window.scrollTo(0, 0);
  }

  render() {
    return (
      <>
        <header className={`header-area header-area-2`}>
          <div className="header-top pl-30 pr-30">
            <Row className="align-items-center">
              <Col md="6" sm="7">
                <div className="header-left-side text-center text-sm-left">
                  <ul>
                    <li><a href="tel:+971 528283037">
                      <FontAwesomeIcon icon={faPhone} className="mr-1" />
                      +971 528283037</a>
                    </li>
                    <li>
                      <a href="mailto:sales@mic-robotics.com" target="__blank">
                        <FontAwesomeIcon icon={faEnvelope} className="mr-1" />
                        sales@mic-robotics.com
                      </a>
                    </li>
                  </ul>
                </div>
              </Col>
              <div className="col-md-6 col-sm-5">
                <div className="header-right-social text-center text-sm-right">
                  <SocialLink />
                </div>
              </div>
            </Row>
          </div>
          <div className="header-nav">
            <div id="navbar" className="navigation">
              <Navbar expand="lg" className={`transparent-color`}>
                <Navbar.Brand as={Link} to={'/'}>
                  <img src={Logo} alt="" width="155" />
                </Navbar.Brand>

                <Navbar.Toggle
                  aria-controls="navbarSupportedContent"
                  aria-label="Toggle navigation">
                  <span className="toggler-icon" />
                  <span className="toggler-icon" />
                  <span className="toggler-icon" />
                </Navbar.Toggle>

                <Navbar.Collapse className="sub-menu-bar" id="navbarSupportedContent">
                  <PartialNav />
                </Navbar.Collapse>
                <div className="navbar-btn mr-100">
                  <button className="main-btn" onClick={this.openModal}>Enquire Now</button>
                  <Modal
                    isOpen={this.state.isOpen}
                    contentLabel="Minimal Modal Example"
                  >
                    <button className="close_btn" onClick={this.closeModal}> X </button>
                    <div className="form-area">
                      <form id="enquire-form" method="post" onSubmit={this.handleSubmit}>
                        <Row>
                          <Col lg="12">
                            <div className="input-box mt-45">
                              <input
                                type="text"
                                name="name"
                                placeholder="Enter your Name"
                                onChange={(e) => this.handleChange(e)}
                                value={this.state.name}
                              />
                            </div>
                          </Col>
                          <Col lg="6">
                            <div className="input-box mt-20">
                              <input
                                type="text"
                                name="phone"
                                placeholder="Enter your Phone Number"
                                onChange={(e) => this.handleChange(e)}
                                value={this.state.phone}
                              />
                            </div>
                          </Col>
                          <Col lg="6">
                            <div className="input-box mt-20">
                              <input
                                type="email"
                                name="email"
                                placeholder="Enter your Email"
                                onChange={(e) => this.handleChange(e)}
                                value={this.state.email}
                              />
                            </div>
                          </Col>
                          <Col lg="12">
                            <div className="input-box mt-20">
                              <textarea
                                name="message"
                                id="index"
                                cols={30}
                                rows={10}
                                defaultValue={""}
                                placeholder="Message"
                                onChange={(e) => this.handleChange(e)}
                                value={this.state.message}
                              />
                            </div>
                          </Col>
                          <Col lg="12">
                            <button className="main-btn" type="submit">
                              Submit
                            </button>
                          </Col>
                        </Row>
                      </form>
                      <p className="form-message" />
                    </div>
                  </Modal>
                </div>
              </Navbar>
            </div>
          </div>
        </header>

      </>
    );
  }
}

export default Header;