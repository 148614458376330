import React from 'react';
import Slider from "react-slick";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faAngleLeft,
  faAngleRight,
} from '@fortawesome/free-solid-svg-icons';
import { Container, Col } from 'react-bootstrap';


function NextArrow(props) {
  const { style, onClick } = props;
  return (
    <span onClick={onClick} className="next slick-arrow" style={style}>
      <FontAwesomeIcon icon={faAngleRight} />
    </span>
  );
}

function PrevArrow(props) {
  const { style, onClick } = props;
  return (
    <span onClick={onClick} className="prev slick-arrow" style={style}>
      <FontAwesomeIcon icon={faAngleLeft} />
    </span>
  );

}

class ProductInnerSlider extends React.Component {

  constructor(props) {
    super()
    this.state = {
      productSliderData: props.productSliderData,
    }
  }

  render() {
    var settings = {
      dots: true,
      infinite: true,
      autoplay: false,
      autoplaySpeed: 5000,
      arrows: true,
      nextArrow: <NextArrow />,
      prevArrow: <PrevArrow />,
      speed: 1000,
      slidesToShow: 1,
      slidesToScroll: 1,
      responsive: [
        {
          breakpoint: 1201,
          settings: {
            slidesToShow: 1,
          }
        },
        {
          breakpoint: 992,
          settings: {
            arrows: false,
            slidesToShow: 1,
          }
        },
        {
          breakpoint: 768,
          settings: {
            arrows: false,
            slidesToShow: 1,
          }
        }
      ]


    };

    return (
      <div className="pt-50 pb-50">
        <Container>
          <Slider className="row product-inner-slider" {...settings}>
            {this.state.productSliderData?.map((item, index) => (
              <Col lg="12" key={index}>
                <div className="mt-30 pb-">
                  <div className="section-title-2 text-center pl-25 pr-25 slider-text-content">
                    <h3 className="title">{item.video_title}</h3>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: `${item.video_description}`,
                      }}
                    ></div>
                  </div>
                  {item.sliders_video &&
                    item.sliders_video.split(".")[3] !== 'png' && item.sliders_video.split(".")[3] !== 'jpg' ?
                    <video
                      controls
                      muted
                      autoPlay={"autoplay"}
                      preload="auto"
                      loop
                      src={"/"+item.sliders_video}
                      style={{ margin: "0 auto", display: "block", width: "100%" }}
                    /> :
                    <img src={process.env.REACT_APP_IMAGE_BASE_URL + item.sliders_video} alt=""   style={{  width: "100%" }} />
                  }

                </div>
                {/* leadership item */}
              </Col>
            ))
            }
          </Slider>
        </Container>
        {/* container */}
      </div>
    );
  }
}

export default ProductInnerSlider;