import React from 'react';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import Home from './pages/home/index-2';
import AboutPage from './pages/about/index';
import Products from './pages/products/index';
import SmartSolutionsPage from './pages/smart-solutions/index';
import BusinessCooperationPage from './pages/business-cooperation/index';
import ContactPage from './pages/contact';
import ComingSoon from './pages/coming-soon';
import FaqPage from './pages/faq';
import ProductDetailPage from "./pages/product-inner";
import TeamPage from './pages/team';
// import TeamDetailsPage from './pages/team/details';
import BlogStandard from './pages/blog/blog-standard';
import BlogGrid from './pages/blog/blog-grid';
import BlogDetails from './pages/blog/blog-details';
import BlogDetails2 from './pages/blog/blog-details2';
import NoMatch from './pages/errors/no-match';
import Preloader from "./componets/preloader/Index";
import PrivacyPolicy from "./pages/privacy-policy/index";
import TermsAndConditions from './pages/terms-and-conditions';
import { API } from './http/API';

class App extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			allPages: "",
			specifiedPage: ""
		};
	}

	componentDidMount() {

		API.get(`/pages`)
			.then((response) => {
				if (response.status === 200 || response.status === 201) {

					this.setState({ allPages: response.data.data });

					let number = response.data.data.filter((data) => data.slug !== 'home-page');

					this.setState({ specifiedPage: number })

					this.state.allPages.filter(person => person.slug !== "home-page")
						.map((item, index) => (
							console.log(item.slug)
						))
				}
			})
			.catch((err) => console.log(err));

	}

	render() {
		return (
			<BrowserRouter basename={'/'} >
				<Preloader />
				<Switch>
					<Route exact path="/" component={Home} />

					<Redirect exact from="/index" to="/" component={Home} />

					<Route path="/about" component={AboutPage} />

					<Route exact path="/products" component={Products} />

					{/* {this.state.allPages?.map(page => (
						page.slug !== "home-page" &&
						page.slug !== "about-us" &&
						page.slug !== "news" &&
						page.slug !== "smart-solution" &&
						page.slug !== "contact-page" &&
						page.slug !== "our-client" &&

						<Route path="/products/:Id" component={ProductDetailPage} />

					))
					} */}

					<Route path="/products/:Id" component={ProductDetailPage} />
					<Route path="/smartsolutions" component={SmartSolutionsPage} />

					<Route path="/business-cooperation" component={BusinessCooperationPage} />

					<Route exact path="/news" component={BlogStandard} />

					<Route path="/news/:Id" component={BlogDetails} />

					<Route path="/contact" component={ContactPage} />

					<Route exact path="/Partner" component={TeamPage} />

					<Route path="/coming-soon" component={ComingSoon} />

					<Route path="/error" component={NoMatch} />

					<Route path="/faq" component={FaqPage} />

					<Route path="/blog-grid" component={BlogGrid} />

					<Route path="/blog-details2" component={BlogDetails2} />

					<Route path="/privacy-policy" component={PrivacyPolicy} />

					<Route path="/terms-and-conditions" component={TermsAndConditions} />

					<Route component={NoMatch} />

				</Switch>
				<ToastContainer />
			</BrowserRouter>
		);
	}
}

export default App;
