import React from 'react';
//import ReactDOM from 'react-dom'
import ModalVideo from 'react-modal-video'
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
} from '@fortawesome/free-solid-svg-icons';
import { faPlay } from "@fortawesome/free-solid-svg-icons";
import { Container, Row, Col } from 'react-bootstrap';

class IntroVideoPart extends React.Component {

  constructor() {
    super()
    this.state = {
      isOpen: false,
      smartSolutionData: {},
    }
    this.openModal = this.openModal.bind(this)
  }

  componentDidMount() {
    this.setState({ smartSolutionData: this.props.smartSolutionSection })
  }


  openModal(e) {
    e.preventDefault();
    this.setState({ isOpen: true })
  }

  render() {

    return (
      <div className="intro-video-area bg_cover"
        style={{ backgroundImage: "url(assets/images/smart-solutions-background.jpg)" }}
      >
        <div className="intro-overlay">
          <Container>
            <Row>
              <Col lg="6">
                <div className="intro-video-content mt-30">
                  <span>{this.props.smartSolutionSection?.smartSolutionSection?.subtitle}</span>
                  <h2 className="title">{this.props.smartSolutionSection?.smartSolutionSection?.title}</h2>
                  {/* <p className="text-1">
                    Ultricies pellentesque. Ipsum, in egestas pharetra sed morbi. Tincidunt vestibulum eget tellus netus orci pellentesque eu. 
                    </p> */}
                  <div className="text-2" dangerouslySetInnerHTML={{
                    __html: `${this.props.smartSolutionSection?.smartSolutionSection?.description}`,
                  }}>
                  </div>
                  <Link
                    className="main-btn wow fadeInLeft"
                    data-wow-duration="1s"
                    data-wow-delay=".1s"
                    to={"/smartsolutions"}
                  >
                    View in Detail
                  </Link>
                </div>
                {/* intro video content */}
              </Col>
              <Col lg="6">
              <ModalVideo channel='youtube' isOpen={this.state.isOpen} videoId={this.props.smartSolutionSection?.smartSolutionSection?.video_link.split("/")[3]} onClose={() => this.setState({ isOpen: false })} />
                <div className="intro-thumb mt-30">
                  <img src={process.env.REACT_APP_IMAGE_BASE_URL + this.props.smartSolutionSection?.smartSolutionSection?.video_image} alt="" />
                  <Link className="video-popup" to={this.props.smartSolutionSection?.smartSolutionSection?.video_link} onClick={this.openModal}>
                    <FontAwesomeIcon icon={faPlay} size={`3x`} className={`pl-2 pt-3`} />
                  </Link>
                </div>
                {/* intro thumb */}
              </Col>
            </Row>
            {/* row */}
          </Container>
          {/* container */}
        </div>
      </div>
    );
  }
}

export default IntroVideoPart;