import React from 'react';
import BlogSideBar from '../../componets/blog/BlogSideBar';
import Layout from '../../layouts';
import { Container, Row, Col } from 'react-bootstrap';
import { API } from '../../http/API';
import { Helmet } from "react-helmet";

class BlogStandard extends React.Component {
  constructor(props) {
    super()
    this.state = {
      newsData: [],
      pagData: [],
    }
  }
  componentDidMount() {

    API.get(`/pages`)
      .then((response) => {
        if (response.status === 200 || response.status === 201) {
          let currentPage = response.data.data.find(
            (x) => x.slug === "news"
          );
          this.setState({ pagData: currentPage });
        }
      }).catch((err) => console.log(err));

    API.get(`/news`)
      .then((response) => {
        if (response.status === 200 || response.status === 201) {
          this.setState({ newsData: response.data.data });
        }
      })
      .catch((err) => console.log(err));
  }


  render() {
    return (
      <Layout>
        <Helmet>
          <title>{this.state.pagData?.meta_details?.meta_title}</title>
          <meta name="description" content={this.state.pagData?.meta_details?.meta_description} />
        </Helmet>
        <div className="page-title-area" style={{ backgroundImage: 'url(assets/images/smart-solution-bg.png)' }}>
          <Container>
            <Row>
              <Col lg="12">
                <div className="page-title-item text-center">
                  <h2 className="title">News & Events</h2>
                  <span style={{ color: '#fff', float: 'left' }}>Stay informed about the recent news and happenings at Mic Robotics</span>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        <div>
          <BlogSideBar
            newsData={this.state.newsData}
          />
        </div>;
      </Layout>
    );
  }
}

export default BlogStandard;