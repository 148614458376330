import React from 'react';
import { Link } from "react-router-dom";
import { Row, Col } from 'react-bootstrap';
// import product1 from '../../../../public/assets/images/menu01.png';
import { API } from '../../../http/API';


class DropDown extends React.Component {
    constructor() {
        super()
        this.state = {
            productList : [],
            // dropdownstate: [
            //     {
            //         title: "KettyBot",
            //         img: '../../assets/images/menu01.png',
            //         short_description: "Delivery & Reception Robot",
            //         url: "/products/ketty-bot",
            //     },
            //     {
            //         title: "BellaBot",
            //         img: '../../assets/images/menu02.png',
            //         short_description: "Delivery Robot",
            //         url: "/products/bella-bot",
            //     },
            //     {
            //         title: "Puductor",
            //         img: '../../assets/images/menu03.png',
            //         short_description: "Disinfection Robot",
            //         url: "/products/puductor",
            //     },
            //     {
            //         title: "HolaBot",
            //         img: '../../assets/images/menu04.png',
            //         short_description: "Delivery Robot",
            //         url: "/products/hola-bot",
            //     },

            // ]

        }

    }

    componentDidMount() {

        API.get(`/products`)
          .then((response) => {
            if (response.status === 200 || response.status === 201) {
              this.setState({ productList: response.data.data });
            }
          })
          .catch((err) => console.log(err));
      }

    render() {
        return (
            <div className="dropdown_main">
                <Row>
                    {this.state.productList.map((item, index) => (
                        <Col md={3} key={index}>
                            <div className="inner_drop_box">
                                <Link className="nav-link" to={`/products/${item.slug}`}><img src={process.env.REACT_APP_IMAGE_BASE_URL + item.whatWeDo.featured_img} alt="" /></Link>
                                <Link className="nav-link" to={`/products/${item.slug}`}><h4>{item.title}</h4></Link>
                                <p>{item.sub_title}</p>
                            </div>
                        </Col>
                    ))}
                </Row>
            </div >
        );
    }
}

export default DropDown;