import React from "react";
import ModalVideo from "react-modal-video";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import Layout from "../../layouts";
import { Container, Row, Col } from "react-bootstrap";
import SocialLink from "../../layouts/partials/header/SocialLink";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faPhone } from "@fortawesome/free-solid-svg-icons";
import { API } from "../../http/API";
import { Helmet } from "react-helmet";

class ContactPage extends React.Component {
  constructor() {
    super();
    this.state = {
      isOpen: false,
      type: "getintouch",
      firstname: "",
      lastname: "",
      phone: "",
      email: "",
      message: "",
      pagData: [],
      conatctBannerData: [],
    };
    this.openModal = this.openModal.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.validateEmail = this.validateEmail.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  openModal = (event) => {
    this.setState({ isOpen: true });
    event.preventDefault();
  };

  validateEmail(email) {
    const pattern =
      /[a-zA-Z0-9]+[\.]?([a-zA-Z0-9]+)?[\@][a-z]{3,9}[\.][a-z]{2,5}/g;
    const result = pattern.test(email);
    if (result === true) {
      this.setState({
        emailError: false,
        email: email,
      });
    } else {
      this.setState({
        emailError: true,
      });
    }
  }

  handleChange(e) {
    const target = e.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    this.setState({
      [name]: value,
    });
    if (e.target.name === "firstname") {
      if (e.target.value === "" || e.target.value === null) {
        this.setState({
          firstnameError: true,
        });
      } else {
        this.setState({
          firstnameError: false,
          firstName: e.target.value,
        });
      }
    }
    if (e.target.name === "lastname") {
      if (e.target.value === "" || e.target.value === null) {
        this.setState({
          lastnameError: true,
        });
      } else {
        this.setState({
          lastnameError: false,
          lastName: e.target.value,
        });
      }
    }
    if (e.target.name === "email") {
      this.validateEmail(e.target.value);
    }
  }

  handleSubmit(event) {
    event.preventDefault();

    const getintouchData = {
      firstName: this.state.firstName,
      lastName: this.state.lastName,
      email: this.state.email,
      phone: this.state.phone,
      message: this.state.message,
      type: this.state.type,
    };

    API.post(`/enquiries`, getintouchData)
      .then((response) => {
        toast.success("Thank you for submite quote!");
      })
      .catch((err) => toast.warning("Something went wrong"));
  }

  componentDidMount() {
    API.get(`/pages`)
      .then((response) => {
        if (response.status === 200 || response.status === 201) {
          let currentPage = response.data.data.find(
            (x) => x.slug === "contact-page"
          );
          this.setState({ pagData: currentPage });

          API.get(`/all-sections/${currentPage._id}`)
            .then((res) => {
              let content = res.data.data[res.data.data.length - 1].content;
              this.setState({ conatctBannerData: content });
            })
            .catch((err) => console.log(err));
        }
      })
      .catch((err) => console.log(err));
  }

  render() {
    return (
      <Layout>
        <Helmet>
          <title>{this.state.pagData?.meta_details?.meta_title}</title>
          <meta
            name="description"
            content={this.state.pagData?.meta_details?.meta_description}
          />
        </Helmet>
        <div
          className="page-title-area"
          style={{
            backgroundImage: `url(${
              process.env.REACT_APP_IMAGE_BASE_URL +
              this.state.conatctBannerData?.banner?.banner_image
            })`,
          }}
        >
          <Container>
            <Row>
              <Col lg="8">
                <div className="page-title-item">
                  <h2 className="title">
                    {this.state.conatctBannerData?.contact?.title}
                  </h2>
                  <span style={{ color: "#fff", float: "left" }}>
                    Reach out to us for all your queries related to our robotic
                    solutions and our representatives will be in touch with you
                    as soon as possible.
                  </span>
                </div>
                {/* page title */}
              </Col>
            </Row>
            {/* row */}
          </Container>
          {/* container */}
        </div>
        {/*====== PAGE TITLE PART ENDS ======*/}
        <div>
          {/*====== CONTACT DETAILS PART START ======*/}
          <div className="contact-details-area pt-90 pb-120">
            <Container>
              <Row>
                <Col lg="6">
                  <div className="contact-info mt-30">
                    <div className="contact-item-1">
                      <div className="contact-info-item text-center">
                        <i className="fal fa-phone" />
                        <h5 className="title">Phone Number</h5>
                        <p>
                          <a
                            href={`tel:${this.state.conatctBannerData?.contact1?.phone}`}
                          >
                            <FontAwesomeIcon icon={faPhone} className="mr-1" />
                            {this.state.conatctBannerData?.contact1?.phone}
                          </a>
                        </p>
                      </div>
                      <div className="contact-info-item text-center">
                        <i className="fal fa-envelope" />
                        <h5 className="title">Email Address</h5>
                        <p>
                          <a
                            href={`mailto:${this.state.conatctBannerData?.contact1?.email}`}
                            target="__blank"
                          >
                            <FontAwesomeIcon
                              icon={faEnvelope}
                              className="mr-1"
                            />
                            {this.state.conatctBannerData?.contact1?.email}
                          </a>
                        </p>
                      </div>
                    </div>
                    <div className="contact-item-1">
                      <div className="contact-info-item text-center">
                        <i className="fal fa-map" />
                        <h5 className="title">Office Location</h5>
                        <p>{this.state.conatctBannerData?.contact1?.address}</p>
                      </div>
                      <div className="contact-info-item text-center">
                        <i className="fal fa-globe" />
                        <h5 className="title">Social Network</h5>
                        <SocialLink />
                      </div>
                    </div>
                  </div>
                  {/* contact info */}
                </Col>
                <Col lg="6">
                  <div className="map-area mt-30">
                    <iframe
                      src="https://maps.google.com/maps?q=MIC%20Robotics%203610,%20Churchill%20Executive%20Tower%20-%20Business%20Bay%20-%20Dubai&t=&z=13&ie=UTF8&iwloc=&output=embed"
                      style={{ border: 0 }}
                      allowFullScreen
                      width={600}
                      height={450}
                      title="Our Localtion"
                    />
                  </div>
                  {/* map area */}
                </Col>
              </Row>
              {/* row */}
            </Container>
            {/* container */}
          </div>
          {/*====== CONTACT DETAILS PART ENDS ======*/}
          {/*====== GET IN TOUCH PART START ======*/}
          <div className="get-in-touch-area get-in-touch-area-2">
            <Container>
              <Row>
                <Col lg="6">
                  <div className="section-title text-left">
                    <span>Get In Touch</span>
                    {/* <h2 className="title">Semper morbi ma.</h2> */}
                  </div>
                  {/* section title */}
                  <div className="form-area">
                    <form
                      id="contact-form"
                      action="assets/contact"
                      method="post"
                      onSubmit={this.handleSubmit}
                    >
                      <Row>
                        <Col lg="12">
                          <div className="input-box mt-45">
                            <input
                              type="text"
                              name="name"
                              placeholder="Enter your name"
                              onChange={(e) => this.handleChange(e)}
                              value={this.state.name}
                            />
                          </div>
                        </Col>
                        <Col lg="6">
                          <div className="input-box mt-20">
                            <input
                              type="email"
                              name="email"
                              placeholder="Enter your email"
                              onChange={(e) => this.handleChange(e)}
                              value={this.state.email}
                            />
                          </div>
                        </Col>
                        <Col lg="6">
                          <div className="input-box mt-20">
                            <input
                              type="text"
                              name="phone"
                              placeholder="Enter your phone number"
                              onChange={(e) => this.handleChange(e)}
                              value={this.state.phone}
                            />
                          </div>
                        </Col>
                        <Col lg="12">
                          <div className="input-box mt-20">
                            <textarea
                              name="message"
                              id="index"
                              cols={30}
                              rows={10}
                              placeholder="Enter your message"
                              defaultValue={""}
                              onChange={(e) => this.handleChange(e)}
                              value={this.state.message}
                            />
                            <button
                              className="main-btn"
                              type="submit"
                              onSubmit={this.handleSubmit}
                            >
                              Submit
                            </button>
                          </div>
                        </Col>
                      </Row>
                    </form>
                    <p className="form-message" />
                  </div>
                </Col>
                <ModalVideo
                  channel="youtube"
                  isOpen={this.state.isOpen}
                  videoId="xlWlrT1UmaA"
                  onClose={() => this.setState({ isOpen: false })}
                />

                <Col lg="6">
                  <div className="get-map d-none d-lg-block mt-40">
                    <img src="/assets/images/hqdefault.jpg" alt="video play" />
                    <Link
                      className="video-popup"
                      to={"https://www.youtube.com/watch?v=xlWlrT1UmaA"}
                      onClick={this.openModal}
                    >
                      <i className="fas fa-play" />
                    </Link>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
          {/*====== GET IN TOUCH PART ENDS ======*/}
        </div>
      </Layout>
    );
  }
}

export default ContactPage;
