import React from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faLongArrowAltLeft,
  faLongArrowAltRight,
} from "@fortawesome/free-solid-svg-icons";
import { Container, Row } from "react-bootstrap";
import { API } from "../../http/API";
import Slider from "react-slick";

function NextArrow(props) {
  const { style, onClick } = props;
  return (
    <span onClick={onClick} className="next slick-arrow" style={style}>
      <FontAwesomeIcon icon={faLongArrowAltRight} />
    </span>
  );
}

function PrevArrow(props) {
  const { style, onClick } = props;
  return (
    <span onClick={onClick} className="prev slick-arrow" style={style}>
      <FontAwesomeIcon icon={faLongArrowAltLeft} />
    </span>
  );
}

class WhoWeAreArea extends React.Component {
  constructor(props) {
    super();
    this.state = {
      whoWeAreSection: {},
      productList: [],
    };
  }

  componentDidMount() {
    API.get(`/pages`)
      .then((response) => {
        if (response.status === 200 || response.status === 201) {
          let homePage = response.data.data.find((x) => x.slug === "home-page");
          if (homePage.slug === "home-page") {
            API.get(`/all-sections/${homePage._id}`)
              .then((res) => {
                let contenthome =
                  res.data.data[res.data.data.length - 1].content;
                this.setState({ whoWeAreSection: contenthome });
              })
              .catch((err) => console.log(err));
          }
        }
      })
      .catch((err) => console.log(err));

    API.get(`/products`)
      .then((response) => {
        if (response.status === 200 || response.status === 201) {
          this.setState({ productList: response.data.data });
        }
      })
      .catch((err) => console.log(err));
  }

  render() {
    var settings = {
      dots: false,
      infinite: true,
      autoplay: true,
      autoplaySpeed: 4000,
      arrows: true,
      nextArrow: <NextArrow />,
      prevArrow: <PrevArrow />,
      speed: 1500,
      slidesToShow: 3,
      slidesToScroll: 1,
      responsive: [
        {
          breakpoint: 1201,
          settings: {
            slidesToShow: 3,
          },
        },
        {
          breakpoint: 992,
          settings: {
            arrows: false,
            slidesToShow: 2,
          },
        },
        {
          breakpoint: 767,
          settings: {
            arrows: false,
            slidesToShow: 1,
          },
        },
      ],
    };
    return (
      <div className="who-we-are-area pb-120 gray-bg">
        <Container>
          <Row>
            <div
              className="col-lg-5 wow fadeInLeft"
              data-wow-duration="1s"
              data-wow-delay="0s"
            >
              <div className="section-title">
                <span>{this.props.whoWeAreSection?.whatWeDo?.subtitle}</span>
                <h2 className="title">
                  {this.props.whoWeAreSection?.whatWeDo?.title}
                </h2>
              </div>
              {/* section title */}
            </div>
            <div
              className="col-lg-7 wow fadeInRight"
              data-wow-duration="1.5s"
              data-wow-delay="0s"
            >
              <div className="section-title">
                <p
                  dangerouslySetInnerHTML={{
                    __html: `${this.props.whoWeAreSection?.whatWeDo?.description}`,
                  }}
                ></p>
              </div>
              {/* section title */}
            </div>
          </Row>
          {/* <Row> */}
          <Slider className="" {...settings}>
            {this.state.productList?.map((item, index) => (
              <div
                // className="col-lg-3 col-md-6 col-sm-6 wow slideInUp"
                className="wow slideInUp"
                data-wow-duration=".5s"
                data-wow-delay="0s"
                key={index}
              >
                <div
                  className="what-we-do-item mt-30"
                  style={{
                    backgroundImage: `url(${
                      process.env.REACT_APP_IMAGE_BASE_URL +
                      item.whatWeDo.featured_img
                    })`,
                  }}
                >
                  {/* <span>{item.sub_title}</span> */}
                  <h5 className="title">{item.title}</h5>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: `${item.whatWeDo.short_description}`,
                    }}
                  ></div>
                  <Link to={`/products/${item.slug}`}>
                    <FontAwesomeIcon icon={faLongArrowAltRight} />
                  </Link>
                </div>
                {/* what we do item */}
              </div>
            ))}
          </Slider>
          {/* </Row> */}
          {/* row */}
        </Container>
        {/* container */}
        <div className="what-we-are-shape-1">
          <img src="/assets/images/what-we-are-shape-1.png" alt="shape" />
        </div>
        <div className="what-we-are-shape-2">
          <img src="/assets/images/what-we-are-shape-2.png" alt="shape" />
        </div>
      </div>
    );
  }
}

export default WhoWeAreArea;
