import React from 'react';
import { Link } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap';
import { API } from '../../http/API';
import dateFormat, { masks } from "dateformat";

class LatestNewsPart extends React.Component {

  constructor(props) {
    super()
    this.state = {
      blogList: [],
      now : new Date(),
    }
  }

  componentDidMount() {
    API.get(`/news`)
      .then((response) => {
        if (response.status === 200 || response.status === 201) {
          this.setState({ blogList: response.data.data });
        }
      })
      .catch((err) => console.log(err));
  }


  render() {
    return (
      <div className="latest-news-area gray-bg">
        <Container>
          <Row className="justify-content-center">
            <Col lg="6">
              <div className="section-title text-center">
                <span>latest news</span>
                <h2 className="title">Check Out Our Latest News</h2>
              </div>
            </Col>
          </Row>
        </Container>
        <div className="letast-news-grid white-bg  ml-40 mr-40">
          <Container>
            <Row>
              <Col lg="12">
                <div className="latest-news">
                  <Row>
                    {this.state.blogList.slice(0, 3).map((item, index) => (
                      <div
                        className="col-lg-4 col-md-6 wow slideInUp"
                        data-wow-duration=".5s"
                        data-wow-delay="0s"
                        key={index}
                      >
                        <div className="letest-news-item mt-30">
                          <span>By Admin, {dateFormat(item.created_at, "d mmm yyyy")}</span>
                          <h4 className="title">
                            <Link to={`/news/${item.slug}`}>{item.title}</Link>
                          </h4>
                          <div
                            dangerouslySetInnerHTML={{
                              __html: `${item.short_description}`,
                            }}
                          ></div>
                          <Link to={`/news/${item.slug}`} className="main-btn">
                            Read
                          </Link>
                        </div>
                      </div>
                    ))}

                  </Row>
                </div>
                {/* latest news */}
              </Col>
            </Row>
            {/* row */}
          </Container>
          {/* container */}
        </div>
      </div>
    );
  }
}

export default LatestNewsPart;