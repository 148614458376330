import React from "react";
import { Link } from "react-router-dom";
import AboutExperiencePart from "../../componets/aboutExperiencePart/Index";
import { Container, Row, Col } from "react-bootstrap";
import Layout from "../../layouts";
import WhoWeAreArea from "../../componets/whoWeAreArea/Index";
import LeadershipPart from "../../componets/LeadershipPart/Index";
import OurValue from "../../componets/aboutOurValue/Index";
import { API } from "../../http/API";
import { Helmet } from "react-helmet";

class AboutPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      aboutData: {},
      pagData: {},
      whatWeDoData: {},
    };
  }

  componentDidMount() {
    API.get(`/pages`)
      .then((response) => {
        if (response.status === 200 || response.status === 201) {
          let currentPage = response.data.data.find(
            (x) => x.slug === "about-us"
          );
          this.setState({ pagData: currentPage });

          if (currentPage.slug === "about-us") {
            API.get(`/all-sections/${currentPage._id}`)
              .then((res) => {
                let content = res.data.data[res.data.data.length - 1].content;
                this.setState({ aboutData: content });
              })
              .catch((err) => console.log(err));
          }

          let homePage = response.data.data.find((x) => x.slug === "home-page");
          if (homePage.slug === "home-page") {
            API.get(`/all-sections/${homePage._id}`)
              .then((res) => {
                let contenthome =
                  res.data.data[res.data.data.length - 1].content;
                this.setState({ whatWeDoData: contenthome });
              })
              .catch((err) => console.log(err));
          }
        }
      })
      .catch((err) => console.log(err));
  }

  render() {
    return (
      <Layout>
        <Helmet>
          <title>{this.state.pagData?.meta_details?.meta_title}</title>
          <meta
            name="description"
            content={this.state.pagData?.meta_details?.meta_description}
          />
        </Helmet>
        <div
          className="page-title-area"
          style={{
            backgroundImage: `url(${
              process.env.REACT_APP_IMAGE_BASE_URL +
              this.state.aboutData?.banner?.banner_image
            })`,
          }}
        >
          <Container>
            <Row>
              <Col lg="12">
                <div className="page-title-item text-center">
                  <h2 className="title">About Us</h2>
                  <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                      <li className="breadcrumb-item">
                        <Link to={"index"}>Home</Link>
                      </li>
                      <li
                        className="breadcrumb-item active"
                        aria-current="page"
                      >
                        About
                      </li>
                    </ol>
                  </nav>
                </div>
                {/* page title */}
              </Col>
            </Row>
            {/* row */}
          </Container>
          {/* container */}
        </div>
        {/*====== PAGE TITLE PART ENDS ======*/}
        <AboutExperiencePart introSection={this.state.aboutData} />
        <OurValue introSection={this.state.aboutData} />
        <WhoWeAreArea whoWeAreSection={this.state.whatWeDoData} />
        <div className="about_leader">
          <LeadershipPart />
        </div>

        {/* <AboutIntroPart/>
            <AbotFaqPart/> */}
      </Layout>
    );
  }
}

export default AboutPage;
