import React from 'react';
import Slider from "react-slick";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faLongArrowAltLeft,
  faLongArrowAltRight,
} from '@fortawesome/free-solid-svg-icons';
import { Container, Row, Col } from 'react-bootstrap';
import { API } from '../../http/API';


function NextArrow(props) {
  const { style, onClick } = props;
  return (
    <span onClick={onClick} className="next slick-arrow" style={style}>
      <FontAwesomeIcon icon={faLongArrowAltRight} />
    </span>
  );
}

function PrevArrow(props) {
  const { style, onClick } = props;
  return (
    <span onClick={onClick} className="prev slick-arrow" style={style}>
      <FontAwesomeIcon icon={faLongArrowAltLeft} />
    </span>
  );

}

class LeadershipPart extends React.Component {

  constructor(props) {
    super()
    this.state = {
      clientData: [],
    }
  }

  componentDidMount() {
    API.get(`/clients`)
      .then((response) => {
        if (response.status === 200 || response.status === 201) {
          this.setState({ clientData: response.data.data });
        }
      })
      .catch((err) => console.log(err));
  }

  render() {
    var settings = {
      dots: false,
      infinite: true,
      autoplay: true,
      autoplaySpeed: 2000,
      arrows: true,
      nextArrow: <NextArrow />,
      prevArrow: <PrevArrow />,
      speed: 1000,
      slidesToShow: 4,
      slidesToScroll: 1,
      responsive: [
        {
          breakpoint: 1201,
          settings: {
            slidesToShow: 4,
          }
        },
        {
          breakpoint: 992,
          settings: {
            arrows: false,
            slidesToShow: 1,
          }
        },
        {
          breakpoint: 767,
          settings: {
            arrows: false,
            slidesToShow: 1,
          }
        }
      ]


    };

    return (
      <div className="leadership-area gray-bg pt-105">
        <Container>
          <Row className="justify-content-center">
            <div className="col-lg-8 col-md-10">
              <div className="section-title-2 text-center pl-25 pr-25">
                <h2 className="title">Our Clients</h2>
                <p>Mic Robotics offers innovative robotic solutions to a roster of clients from the hotel, medical and catering industries while always striving to come up with solutions to meet their diverse needs.</p>
              </div>
            </div>
          </Row>
          <Slider className="row leadership-active" {...settings}>
            {this.state.clientData?.map((item, index) => (
              <div className="leadership-item mt-30" key={index}>
                <img src={process.env.REACT_APP_IMAGE_BASE_URL + item.featured_img} alt="" />
              </div>
            ))
            }
          </Slider>
        </Container>
      </div>
    );
  }
}

export default LeadershipPart;