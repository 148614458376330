import React from 'react';
import Layout from '../../layouts';
import { Container, Row, Col, Button } from 'react-bootstrap';
import ModalVideo from 'react-modal-video';
import { Link } from 'react-router-dom';
import ProductInnerSlider from '../../componets/productInnerSlider/Index';
import ProductInnerDetails from '../../componets/productInnerDetails/Index';
import ProductInnerColorSchemes from '../../componets/productInnerColorSchemes/Index';
import patternImg from '../../assets/images/ProductInnerPage/BellaBot Pattern.png';
import bellaBotFace from '../../assets/images/ProductInnerPage/BellaBot Face.png';
import { API } from '../../http/API';
import Modal from 'react-modal';

class ProductInner extends React.Component {

    constructor(props) {
        super()
        this.state = {
            isOpen: false,
            isOpenNew: false,
            isOpenForm: false,
            productData: "",
            currentPage: window.location.pathname.split('/'),
            newCurrentPage: "",
            apiCall: true
        }
        this.openModal = this.openModal.bind(this);
        this.openModalNew = this.openModalNew.bind(this);
        this.handlegetData = this.handlegetData.bind(this);
        this.closeModal = this.closeModal.bind(this);
        this.openForm = this.openForm.bind(this);
    }

    openModal = event => {
        this.setState({ isOpen: true });
        event.preventDefault();
    }
    openModalNew = event => {
        this.setState({ isOpenNew: true });
        event.preventDefault();
    }

    openForm = event => {
        this.setState({ isOpenForm: true });
        event.preventDefault();
    }
    closeModal() {
        this.setState({ isOpenForm: false })
    }


    handlegetData = () => {
        if (this.state.apiCall) {
            // if(page){
                
            // } else {

            // }
            API.get(`/products/${this.state.currentPage[2]}`)
                .then((response) => {
                    if (response.status === 200 || response.status === 201) {
                        this.setState({ productData: response.data.data });
                    }
                })
                .catch((err) => console.log(err));
            this.setState({ apiCall: false })
        }
    }

    componentDidMount() {
        this.handlegetData();
    }

    componentDidUpdate() {

        let newCurrentPage = window.location.pathname.split('/');
        if (this.state.currentPage[2] != newCurrentPage[2]) {
            this.setState({ currentPage: newCurrentPage });
            this.setState({ apiCall: true })
            window.location.reload(false);
        }

        this.handlegetData();

    }

    render() {
        return (
            this.state.productData !== "" && (
                <Layout>
                    <div className="product-page-title-area">
                        <Container>
                            <Row className="product_banner_row">
                                <Col lg="6">
                                    <div className="page-title-item">
                                        <h2 className="title" style={{ color: '#0072FF' }}>{this.state.productData?.title}</h2>
                                        <p style={{ color: 'black' }}>{this.state.productData?.sub_title}</p>
                                    </div>
                                </Col>
                                <Col lg="6">
                                    <div className="page-title-item" style={{ textAlign: 'right' }}>
                                        <img src={process.env.REACT_APP_IMAGE_BASE_URL + this.state.productData?.featured_img} className="feat-product-image" />
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                    {this.state.productData?.product_video_detail !== undefined &&
                        <div className="pt-150 pb-50 inner-product-padding-view">
                            <Container>
                                <Row className="get-in-touch-area get-in-touch-area-2 product-inner-video">
                                    <ModalVideo channel='youtube' isOpen={this.state.isOpenNew} videoId={this.state.productData?.product_video_detail?.product_video_link.split("/watch?v=")[1]} onClose={() => this.setState({ isOpenNew: false })} />
                                    <Col lg="4">
                                        <div className="get-map d-none d-lg-block">
                                            <img src={process.env.REACT_APP_IMAGE_BASE_URL + this.state.productData?.product_video_detail?.product_video_image} alt="video play" />
                                            <Link className="video-popup" to={this.state.productData?.product_video_detail?.product_video_link} onClick={this.openModalNew}>
                                                <i className="fas fa-play" />
                                            </Link>
                                        </div>
                                    </Col>
                                    <Col lg="8">
                                        <p>{this.state.productData?.product_video_detail?.product_video_sub_title}</p>
                                        <br />
                                        <h2>{this.state.productData?.product_video_detail?.product_video_title}</h2>
                                        <br />
                                        <div
                                            dangerouslySetInnerHTML={{
                                                __html: `${this.state.productData?.product_video_detail?.product_video_description}`,
                                            }}
                                        ></div>
                                        <br />
                                        <Button className="product-inner-btn" onClick={this.openForm}>Enquire Now</Button>
                                        <Modal
                                            isOpen={this.state.isOpenForm}
                                            contentLabel="Minimal Modal Example"
                                        >
                                            <button className="close_btn" onClick={this.closeModal}> X </button>
                                            <div className="form-area">
                                                <form id="enquire-form" method="post" onSubmit={this.handleSubmit}>
                                                    <Row>
                                                        <Col lg="12">
                                                            <div className="input-box mt-45">
                                                                <input
                                                                    type="text"
                                                                    name="name"
                                                                    placeholder="Enter your Name"
                                                                    onChange={(e) => this.handleChange(e)}
                                                                    value={this.state.name}
                                                                />
                                                            </div>
                                                        </Col>
                                                        <Col lg="6">
                                                            <div className="input-box mt-20">
                                                                <input
                                                                    type="text"
                                                                    name="phone"
                                                                    placeholder="Enter your Phone Number"
                                                                    onChange={(e) => this.handleChange(e)}
                                                                    value={this.state.phone}
                                                                />
                                                            </div>
                                                        </Col>
                                                        <Col lg="6">
                                                            <div className="input-box mt-20">
                                                                <input
                                                                    type="email"
                                                                    name="email"
                                                                    placeholder="Enter your Email"
                                                                    onChange={(e) => this.handleChange(e)}
                                                                    value={this.state.email}
                                                                />
                                                            </div>
                                                        </Col>
                                                        <Col lg="12">
                                                            <div className="input-box mt-20">
                                                                <textarea
                                                                    name="message"
                                                                    id="index"
                                                                    cols={30}
                                                                    rows={10}
                                                                    defaultValue={""}
                                                                    placeholder="Message"
                                                                    onChange={(e) => this.handleChange(e)}
                                                                    value={this.state.message}
                                                                />
                                                            </div>
                                                        </Col>
                                                        <Col lg="12">
                                                            <button className="main-btn" type="submit">
                                                                Submit
                                                            </button>
                                                        </Col>
                                                    </Row>
                                                </form>
                                                <p className="form-message" />
                                            </div>
                                        </Modal>
                                    </Col>
                                </Row>
                            </Container>
                        </div>
                    }
                    <div>
                        <ProductInnerSlider
                            productSliderData={this.state.productData?.video_slider}
                        />
                    </div>

                    <div className="pt-50">
                        {this.state.productData?.testimonials?.testimonials_video_url!==null &&
                        <Container className="product-inner-testimonial">
                            <Row className="get-in-touch-area get-in-touch-area-2 product-inner-img" style={{ alignItems: 'center' }}>
                                <ModalVideo channel='youtube' isOpen={this.state.isOpen} videoId={this.state.productData?.testimonials?.testimonials_video_url.split("/watch?v=")[1]} onClose={() => this.setState({ isOpen: false })} />
                                <Col lg="8">
                                    {/* <span>Pellentesque</span> */}
                                    <br />
                                    <h2>{this.state.productData?.testimonials?.title}</h2>
                                    <br />
                                    {this.state.productData?.testimonials?.description !== '' &&
                                        this.state.productData?.testimonials?.description !== null &&
                                        this.state.productData?.testimonials?.description !== undefined &&
                                        <div
                                            dangerouslySetInnerHTML={{
                                                __html: `${this.state.productData?.testimonials?.description}`,
                                            }}
                                        ></div>
                                    }
                                    {/* <Link className="product-inner-btn" to={this.state.productData?.testimonials?.testimonials_video_url} onClick={this.openModal}>

                                    </Link> */}
                                    {/* <Button className="product-inner-btn" tothis.state.productData?.testimonials?.testimonials_video_url} onClick={this.openModal}>Watch Now</Button> */}
                                </Col>
                                <Col lg="4">
                                    <div className="get-map mt-40 testimonials-get">
                                        <img src={`${process.env.REACT_APP_IMAGE_BASE_URL + this.state.productData?.testimonials?.testimonials_img}`} alt="dummy image" />
                                        <Link className="video-popup" to={this.state.productData?.testimonials?.testimonials_video_url} onClick={this.openModal}>
                                            <i className="fas fa-play" />
                                        </Link>
                                    </div>
                                </Col>

                            </Row>
                        </Container>
                        }
                    </div>
                    {this.state.currentPage[2] === "bella-bot" &&
                        <div>
                            <Container fluid className="product-inner-smart mt-50 mb-50 pt-50 pb-50">
                                <Row>
                                    <Col className="product-inner-smart-content">
                                        <h3 className="pt-20 pb-20">Smart Expressions</h3>
                                        <p className="pt-20 pb-20">There are dozens of exclusive original expressions, which make the emotional expression of BellaBot more diverse, and make you feel more real "Cat".</p>
                                        <div className="mt-100" style={{ position: "relative", height: "300px" }}>
                                            <img src={patternImg} style={{ width: "100%" }} />
                                            <img src={bellaBotFace} className="smart-face-image" style={{ position: "inherit" }} />
                                        </div>
                                    </Col>
                                </Row>
                            </Container>
                        </div>
                    }

                    <ProductInnerDetails
                        productFeatures={this.state.productData?.advantages_data}
                    />

                    <ProductInnerColorSchemes
                        productColorScheme={this.state.productData?.variations}
                    />

                </Layout>
            )
        );
    }
}

export default ProductInner;