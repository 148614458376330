import React from "react";
import { Link } from "react-router-dom";
import Layout from "../../layouts";
import { Container, Row, Col } from "react-bootstrap";
import { API } from "../../http/API";
import { Helmet } from "react-helmet";

class BlogDetails extends React.Component {
  constructor(props) {
    super();
    this.state = {
      newsData: [],
    };
  }

  componentDidMount() {
    let currentPage = window.location.pathname.split("/");

    API.get(`/news/${currentPage[2]}`)
      .then((response) => {
        if (response.status === 200 || response.status === 201) {
          this.setState({ newsData: response.data.data });
        }
      })
      .catch((err) => console.log(err));
  }

  render() {
    return (
      <Layout>
        <Helmet>
          <title>{this.state.newsData?.meta_details?.meta_title}</title>
          <meta
            name="description"
            content={this.state.newsData?.meta_details?.meta_description}
          />
        </Helmet>
        <div
          className="page-title-area blogs-title-area"
          style={{
            backgroundImage: "url(../../assets/images/smart-solution-bg.png)",
          }}
        >
          <Container>
            <Row>
              <Col lg="12">
                <div className="page-title-item text-center">
                  <h2 className="title">{this.state.newsData.title}</h2>
                  <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                      <li className="breadcrumb-item">
                        <Link to={"/"}>Home </Link>
                      </li>
                      <li className="breadcrumb-item">
                        <Link to={"/news"}>News </Link>
                      </li>
                      <li
                        className="breadcrumb-item active"
                        aria-current="page"
                      >
                        {this.state.newsData.title}
                      </li>
                    </ol>
                  </nav>
                </div>
                {/* page title */}
              </Col>
            </Row>
            {/* row */}
          </Container>
          {/* container */}
        </div>
        <div className="blog-standard-area pt-90 pb-120">
          <Container>
            <Row className="justify-content-center">
              <Col lg="12">
                <div className="blog-standard">
                  <div className="single-blog-standard  mt-30">
                    <div className="blog-dteails-content blog-border">
                      <div className="blog-details-top">
                        <h2 className="title">{this.state.newsData.title}</h2>
                      </div>

                      <div className="blog-details-bath">
                        <img
                          src={
                            process.env.REACT_APP_IMAGE_BASE_URL +
                            this.state.newsData.featured_image
                          }
                          alt="blog-details"
                        />
                      </div>

                      <div
                        className="blog-details-confarance"
                        dangerouslySetInnerHTML={{
                          __html: `${this.state.newsData.content}`,
                        }}
                      ></div>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </Layout>
    );
  }
}

export default BlogDetails;
