import React from 'react';
import './Index.scss';
import { Container, Row } from 'react-bootstrap';

class Index extends React.Component {

  constructor() {
    super()
    this.state = {
    }
  }

  render() {
    return (
      <div className="about-our-Value-area pb-120">
        <Container>
          <Row>
            <div className="experience-item">
              <h2 className="title">Our Value</h2>
            </div>
          </Row>
          <Row className="justify-content-center">
            <div
              className="col-lg-2 col-md-2 col-sm-12 wow fadeInUp"
              data-wow-duration=".5s"
              data-wow-delay=".2s"
            >
              <div className="our-value-icons">
                <svg width="41" height="41" viewBox="0 0 41 41" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M34.0912 8.74679L34.0917 8.74728C34.804 9.45925 35.3691 10.3046 35.7546 11.235C36.1401 12.1654 36.3385 13.1626 36.3385 14.1697C36.3385 15.1768 36.1401 16.174 35.7546 17.1044C35.3691 18.0348 34.804 18.8802 34.0917 19.5921L34.0915 19.5924L32.3248 21.359L20.4188 33.2651L8.51281 21.359L6.74615 19.5924C5.30796 18.1542 4.5 16.2036 4.5 14.1697C4.5 12.1358 5.30796 10.1852 6.74615 8.74704C8.18433 7.30885 10.1349 6.50089 12.1688 6.50089C14.2027 6.50089 16.1533 7.30885 17.5915 8.74704L19.3582 10.5137C19.9439 11.0995 20.8937 11.0995 21.4795 10.5137L23.2461 8.74704L23.2464 8.74679C23.9584 8.03449 24.8037 7.46945 25.7341 7.08393C26.6645 6.69842 27.6617 6.5 28.6688 6.5C29.6759 6.5 30.6732 6.69842 31.6036 7.08393C32.534 7.46945 33.3793 8.03449 34.0912 8.74679Z" stroke="white" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" />
                </svg>
              </div>
              <div className="our-value-label">
                <p>Be Customer-centric</p>
              </div>
            </div>
            <div
              className="col-lg-2 col-md-2 col-sm-12 wow fadeInUp"
              data-wow-duration="1s"
              data-wow-delay=".4s"
            >
              <div className="our-value-icons">
                <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M23.5 8.33331C23.5 10.2663 21.933 11.8333 20 11.8333C18.067 11.8333 16.5 10.2663 16.5 8.33331C16.5 6.40032 18.067 4.83331 20 4.83331C21.933 4.83331 23.5 6.40032 23.5 8.33331Z" stroke="white" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" />
                  <path d="M20 36.6666V13.3333" stroke="white" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" />
                  <mask id="path-3-inside-1_150_1331" fill="white">
                    <path d="M8.33337 20H3.33337C3.33337 24.4203 5.08932 28.6595 8.21493 31.7851C11.3405 34.9107 15.5798 36.6667 20 36.6667C24.4203 36.6667 28.6595 34.9107 31.7852 31.7851C34.9108 28.6595 36.6667 24.4203 36.6667 20H31.6667" />
                  </mask>
                  <path d="M8.33337 23C9.99023 23 11.3334 21.6569 11.3334 20C11.3334 18.3431 9.99023 17 8.33337 17V23ZM3.33337 20V17C2.53772 17 1.77466 17.3161 1.21205 17.8787C0.649445 18.4413 0.333374 19.2044 0.333374 20H3.33337ZM36.6667 20H39.6667C39.6667 18.3431 38.3236 17 36.6667 17V20ZM31.6667 17C30.0099 17 28.6667 18.3431 28.6667 20C28.6667 21.6569 30.0099 23 31.6667 23V17ZM8.33337 17H3.33337V23H8.33337V17ZM0.333374 20C0.333374 25.2159 2.40539 30.2182 6.09361 33.9064L10.3362 29.6638C7.77325 27.1008 6.33337 23.6246 6.33337 20L0.333374 20ZM6.09361 33.9064C9.78182 37.5947 14.7841 39.6667 20 39.6667V33.6667C16.3754 33.6667 12.8992 32.2268 10.3362 29.6638L6.09361 33.9064ZM20 39.6667C25.216 39.6667 30.2183 37.5947 33.9065 33.9064L29.6638 29.6638C27.1008 32.2268 23.6247 33.6667 20 33.6667V39.6667ZM33.9065 33.9064C37.5947 30.2182 39.6667 25.2159 39.6667 20H33.6667C33.6667 23.6246 32.2268 27.1008 29.6638 29.6638L33.9065 33.9064ZM36.6667 17H31.6667V23H36.6667V17Z" fill="white" mask="url(#path-3-inside-1_150_1331)" />
                </svg>

              </div>
              <div className="our-value-label">
                <p>Be Reliable</p>
              </div>
              {/* single experience */}
            </div>
            <div
              className="col-lg-2 col-md-2 col-sm-12 wow fadeInUp"
              data-wow-duration="1s"
              data-wow-delay=".4s"
            >
              <div className="our-value-icons">
                <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M30.1667 13.3334C30.1667 18.9482 25.6149 23.5 20 23.5C14.3851 23.5 9.83337 18.9482 9.83337 13.3334C9.83337 7.71846 14.3851 3.16669 20 3.16669C25.6149 3.16669 30.1667 7.71846 30.1667 13.3334Z" stroke="white" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" />
                  <mask id="path-2-inside-1_150_1335" fill="white">
                    <path d="M13.6833 23.15L11.6666 38.3334L20 33.3334L28.3333 38.3334L26.3166 23.1334" />
                  </mask>
                  <path d="M16.6572 23.545C16.8753 21.9026 15.7207 20.3943 14.0783 20.1761C12.4359 19.958 10.9276 21.1126 10.7094 22.755L16.6572 23.545ZM11.6666 38.3334L8.69274 37.9384C8.54107 39.0803 9.05669 40.2083 10.0195 40.8408C10.9823 41.4732 12.2223 41.4985 13.2101 40.9058L11.6666 38.3334ZM20 33.3334L21.5434 30.7609C20.5934 30.1909 19.4065 30.1909 18.4565 30.7609L20 33.3334ZM28.3333 38.3334L26.7898 40.9058C27.7776 41.4985 29.0174 41.4732 29.9802 40.8409C30.943 40.2085 31.4587 39.0807 31.3072 37.9388L28.3333 38.3334ZM29.2906 22.7388C29.0727 21.0963 27.5645 19.9415 25.9221 20.1594C24.2796 20.3773 23.1248 21.8855 23.3427 23.5279L29.2906 22.7388ZM10.7094 22.755L8.69274 37.9384L14.6405 38.7284L16.6572 23.545L10.7094 22.755ZM13.2101 40.9058L21.5434 35.9058L18.4565 30.7609L10.1231 35.7609L13.2101 40.9058ZM18.4565 35.9058L26.7898 40.9058L29.8768 35.7609L21.5434 30.7609L18.4565 35.9058ZM31.3072 37.9388L29.2906 22.7388L23.3427 23.5279L25.3594 38.7279L31.3072 37.9388Z" fill="white" mask="url(#path-2-inside-1_150_1335)" />
                </svg>
              </div>
              <div className="our-value-label">
                <p>Be Professional</p>
              </div>
              {/* single experience */}
            </div>
            <div
              className="col-lg-2 col-md-2 col-sm-12 wow fadeInUp"
              data-wow-duration="1s"
              data-wow-delay=".4s"
            >
              <div className="our-value-icons">
                <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M26.6666 35V31.6667C26.6666 29.8986 25.9642 28.2029 24.714 26.9526C23.4638 25.7024 21.7681 25 20 25H8.33329C6.56518 25 4.86949 25.7024 3.61925 26.9526C2.36901 28.2029 1.66663 29.8986 1.66663 31.6667V35" stroke="white" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" />
                  <path d="M14.1667 18.3333C17.8486 18.3333 20.8333 15.3486 20.8333 11.6667C20.8333 7.98477 17.8486 5 14.1667 5C10.4848 5 7.5 7.98477 7.5 11.6667C7.5 15.3486 10.4848 18.3333 14.1667 18.3333Z" stroke="white" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" />
                  <path d="M28.3334 18.3333L31.6667 21.6667L38.3334 15" stroke="white" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" />
                </svg>
              </div>
              <div className="our-value-label">
                <p>Be Cooperative</p>
              </div>
              {/* single experience */}
            </div>
            <div
              className="col-lg-2 col-md-2 col-sm-12 wow fadeInUp"
              data-wow-duration="1s"
              data-wow-delay=".4s"
            >
              <div className="our-value-icons">
                <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <mask id="path-1-inside-1_150_1325" fill="white">
                    <path d="M30 13.3333H31.6667C33.4348 13.3333 35.1305 14.0357 36.3807 15.2859C37.631 16.5362 38.3333 18.2319 38.3333 20C38.3333 21.7681 37.631 23.4638 36.3807 24.714C35.1305 25.9643 33.4348 26.6666 31.6667 26.6666H30" />
                  </mask>
                  <path d="M30 10.3333C28.3431 10.3333 27 11.6765 27 13.3333C27 14.9902 28.3431 16.3333 30 16.3333V10.3333ZM31.6667 13.3333V10.3333V13.3333ZM31.6667 26.6666V29.6666V26.6666ZM30 23.6666C28.3431 23.6666 27 25.0098 27 26.6666C27 28.3235 28.3431 29.6666 30 29.6666V23.6666ZM30 16.3333H31.6667V10.3333H30V16.3333ZM31.6667 16.3333C32.6391 16.3333 33.5718 16.7196 34.2594 17.4073L38.502 13.1646C36.6892 11.3518 34.2304 10.3333 31.6667 10.3333V16.3333ZM34.2594 17.4073C34.947 18.0949 35.3333 19.0275 35.3333 20H41.3333C41.3333 17.4362 40.3149 14.9775 38.502 13.1646L34.2594 17.4073ZM35.3333 20C35.3333 20.9724 34.947 21.9051 34.2594 22.5927L38.502 26.8353C40.3149 25.0225 41.3333 22.5637 41.3333 20H35.3333ZM34.2594 22.5927C33.5718 23.2803 32.6391 23.6666 31.6667 23.6666V29.6666C34.2304 29.6666 36.6892 28.6482 38.502 26.8353L34.2594 22.5927ZM31.6667 23.6666H30V29.6666H31.6667V23.6666Z" fill="white" mask="url(#path-1-inside-1_150_1325)" />
                  <path d="M4.83325 14.8333H28.4999V28.3333C28.4999 29.7036 27.9556 31.0178 26.9866 31.9867C26.0177 32.9556 24.7035 33.5 23.3333 33.5H9.99992C8.62963 33.5 7.31547 32.9556 6.34653 31.9867C5.3776 31.0178 4.83325 29.7036 4.83325 28.3333V14.8333Z" stroke="white" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" />
                  <path d="M10 1.66669V6.66669" stroke="white" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" />
                  <path d="M16.6667 1.66669V6.66669" stroke="white" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" />
                  <path d="M23.3333 1.66669V6.66669" stroke="white" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" />
                </svg>
              </div>
              <div className="our-value-label">
                <p>Be Open</p>
              </div>
              {/* single experience */}
            </div>
          </Row>
          {/* row */}
        </Container>
        {/* container */}
      </div>
    );
  }
}

export default Index;