import React from 'react';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import Layout from '../../layouts';
import { Container, Row, Col } from 'react-bootstrap';
import './index.scss';
import { Helmet } from "react-helmet";
import { API } from '../../http/API';

class BusinessCooperationPage extends React.Component {

  constructor() {
    super()
    this.state = {
      isOpen: false,
      type: "purchase",
      companyName: "",
      name: "",
      phone: "",
      email: "",
      message: "",
      subjectType: "",
    }
    this.openModal = this.openModal.bind(this);

    this.handleChange = this.handleChange.bind(this);

    this.validateEmail = this.validateEmail.bind(this);

    this.handleSubmit = this.handleSubmit.bind(this);

    this.handleTypeChange = this.handleTypeChange.bind(this);

  }

  openModal = event => {
    this.setState({ isOpen: true });
    event.preventDefault();
  }

  handleTypeChange = (val) => {
    this.setState({ type: val });
  }

  validateEmail(email) {
    const pattern = /[a-zA-Z0-9]+[\.]?([a-zA-Z0-9]+)?[\@][a-z]{3,9}[\.][a-z]{2,5}/g;
    const result = pattern.test(email);
    if (result === true) {
      this.setState({
        emailError: false,
        email: email
      })
    } else {
      this.setState({
        emailError: true
      })
    }
  }

  handleChange(e) {
    const target = e.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    this.setState({
      [name]: value
    });
    if (e.target.name === 'name') {
      if (e.target.value === '' || e.target.value === null) {
        this.setState({
          nameError: true
        })
      } else {
        this.setState({
          nameError: false,
          Name: e.target.value
        })
      }
    }
    if (e.target.name === 'email') {
      this.validateEmail(e.target.value);
    }
  }

  handleSubmit(event) {

    event.preventDefault();

    const enquiryData = {
      name: this.state.name,
      email: this.state.email,
      phone: this.state.phone,
      message: this.state.message,
      type: this.state.type,
      companyName: this.state.companyName,
      subjectType: this.state.subjectType,
    }

    API.post(`/enquiries`, enquiryData)
      .then((response) => {
        toast.success("Thank you for submite quote!");
        this.setState({ isOpen: false })
      })
      .catch((err) =>
        toast.warning("Something went wrong"),
        this.setState({ isOpen: false })
      );
  }

  render() {
    return (
      <Layout>
        <Helmet>
          <title>{this.state.pagData?.meta_details?.meta_title}</title>
          <meta name="description" content={this.state.pagData?.meta_details?.meta_description} />
        </Helmet>
        <div className="page-title-area" style={{ backgroundImage: 'url(assets/images/flipimage.jpg)' }}>
          <Container>
            <Row>
              <Col lg="12">
                <div className="page-title-item text-center">
                  <h2 className="title">Business Cooperation</h2>
                  <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                      <li className="breadcrumb-item">
                        <Link to={"/"}>Home </Link>
                      </li>
                      <li className="breadcrumb-item active" aria-current="page">
                        Business Cooperation
                      </li>
                    </ol>
                  </nav>
                </div>
                {/* page title */}
              </Col>
            </Row>
            {/* row */}
          </Container>
          {/* container */}
        </div>
        <div>
          <div className="get-in-touch-area get-in-touch-area-2 business-cooperation-form">
            <Container>
              <Row>
                <Col lg="12">
                  <div className="section-title text-left">
                    <span>Business Cooperation</span>
                    <p style={{ color: '#333' }}>Reach out to us for all of your queries whether you are looking to buy our products or you want to be a distributor. Contact us now and let’s collaborate to build robotic solutions for better performance, efficiency, and productivity. </p>
                  </div>
                  {/* section title */}
                  <div className="form-area">
                    <form id="contact-form" action="index" method="post" onSubmit={this.handleSubmit}>
                      <Row>
                        <Col lg="12">
                          <div className="input-box mt-45">
                            <Row>
                              <Col lg="2">
                                <label>Type*</label>
                              </Col>
                              <Col lg="10">
                                <Row>
                                  <Col lg="4">
                                    <span
                                      className={`bcf-type-btn ${this.state.type === 'purchase' ? 'active' : ''}`}
                                      onClick={() => this.handleTypeChange('purchase')}
                                      type={"button"}
                                    >
                                      Purchase
                                    </span>
                                  </Col>
                                  <Col lg="4">
                                    <span
                                      className={`bcf-type-btn ${this.state.type === 'toBeaPartner' ? 'active' : ''} `}
                                      onClick={() => this.setState({ type: 'toBeaPartner' })}
                                    >
                                      To Be a Partner
                                    </span>
                                  </Col>
                                  <Col lg="4">
                                    <span
                                      className={`bcf-type-btn ${this.state.type === 'businessCooperation' ? 'active' : ''} `}
                                      onClick={() => this.handleTypeChange('businessCooperation')}
                                    >
                                      Business Cooperation
                                    </span>
                                  </Col>
                                </Row>
                              </Col>
                            </Row>
                          </div>
                        </Col>
                        <Col lg="12">
                          <div className="input-box mt-45">
                            <Row>
                              <Col lg="2">
                                <label>Company name*</label>
                              </Col>
                              <Col lg="10">
                                <input
                                  type="text"
                                  name="companyName"
                                  onChange={(e) => this.handleChange(e)}
                                  value={this.state.companyName}
                                />
                              </Col>
                            </Row>

                          </div>
                        </Col>
                        <Col lg="12">
                          <div className="input-box mt-20">
                            <Row>
                              <Col lg="2">
                                <label>Name*</label>
                              </Col>
                              <Col lg="10">
                                <input
                                  type="text"
                                  name="name"
                                  onChange={(e) => this.handleChange(e)}
                                  value={this.state.name}
                                />
                              </Col>
                            </Row>
                          </div>
                        </Col>
                        <Col lg="6">
                          <div className="input-box mt-20">
                            <Row>
                              <Col lg="4">
                                <label>Phone Number*</label>
                              </Col>
                              <Col lg="8">
                                <input
                                  type="text"
                                  name="phone"
                                  onChange={(e) => this.handleChange(e)}
                                  value={this.state.phone}
                                />
                              </Col>
                            </Row>
                          </div>
                        </Col>
                        <Col lg="6">
                          <div className="input-box mt-20">
                            <Row>
                              <Col lg="2">
                                <label>Email*</label>
                              </Col>
                              <Col lg="10">
                                <input
                                  type="email"
                                  name="email"
                                  onChange={(e) => this.handleChange(e)}
                                  value={this.state.email}
                                />
                              </Col>
                            </Row>
                          </div>
                        </Col>
                        <Col lg="12">
                          <div className="input-box mt-20">
                            <Row>
                              <Col lg="2">
                                <label>Message</label>
                              </Col>
                              <Col lg="10">
                                <textarea
                                  name="message"
                                  id="index"
                                  cols={30}
                                  rows={10}
                                  defaultValue={""}
                                  onChange={(e) => this.handleChange(e)}
                                  value={this.state.message}
                                />
                              </Col>
                            </Row>
                            <button className="main-btn" type="submit" onSubmit={this.handleSubmit}>
                              Submit
                            </button>
                          </div>
                        </Col>
                      </Row>
                    </form>
                    <p className="form-message" />
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
          {/*====== GET IN TOUCH PART ENDS ======*/}
        </div>
      </Layout>
    );
  }
}

export default BusinessCooperationPage;