import React from "react";
import { Container, Row, Col } from "react-bootstrap";

class ProductInnerColorSchemes extends React.Component {
  constructor(props) {
    super();
    this.state = {
      productScheme: props.productColorScheme,
    };
  }

  render() {
    return (
      <div className="product-inner-schemes">
        <Container fluid className="text-center">
          <Row className="pb-50">
            <Col xs={12}>
              <h3>Technologically Advanced & Ready to Serve</h3>
            </Col>
          </Row>
          <Row className="pb-50">
            <Col xs={6}>
              <h4>{this.state.productScheme.variations_title1}</h4>
            </Col>
            <Col xs={6}>
              <h4>{this.state.productScheme.variations_title2}</h4>
            </Col>
          </Row>
          <Row
            className="product-inner-schemes-items"
            style={{
              background:
                process.env.REACT_APP_IMAGE_BASE_URL +
                  this.state.productScheme.variations_background_image !==
                ""
                  ? `url(${
                      process.env.REACT_APP_IMAGE_BASE_URL +
                      this.state.productScheme.variations_background_image
                    }`
                  : `black`,
            }}
          >
            <Col xs={6}>
              <div className="product-inner-schemes-inner">
                <img
                  // className="pb-50"
                  src={
                    process.env.REACT_APP_IMAGE_BASE_URL +
                    this.state.productScheme.variation_image1
                  }
                />
                {this.state.productScheme.variations_description1 !== "" &&
                this.state.productScheme.variations_description1 !==
                  undefined ? (
                  <div
                    className="pb-50"
                    dangerouslySetInnerHTML={{
                      __html: `${this.state.productScheme.variations_description1}`,
                    }}
                  ></div>
                ) : (
                  ""
                )}
              </div>
            </Col>
            <Col xs={6}>
              <div className="product-inner-schemes-inner">
                <img
                  className="pb-50"
                  src={
                    process.env.REACT_APP_IMAGE_BASE_URL +
                    this.state.productScheme.variation_image2
                  }
                />
                {this.state.productScheme.variations_description2 !== "" &&
                this.state.productScheme.variations_description2 !==
                  undefined ? (
                  <div
                    className="pb-50"
                    dangerouslySetInnerHTML={{
                      __html: `${this.state.productScheme.variations_description2}`,
                    }}
                  ></div>
                ) : (
                  ""
                )}
              </div>
            </Col>
          </Row>
          {/* row */}
        </Container>
        {/* container */}
      </div>
    );
  }
}

export default ProductInnerColorSchemes;
