import React from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faLongArrowAltRight,
} from '@fortawesome/free-solid-svg-icons';
import { Container, Row } from 'react-bootstrap';
import data from '../../data/data.json';

class ProductInnerDetailsArea extends React.Component {
  constructor(props) {
    super()
    this.state = {
      productFeatures: props.productFeatures,
    }
  }

  render() {
    return (
      <div className="product-inner-details pb-120">
        <Container>
          <Row>
            <div
              className="col-lg-12 wow fadeInLeft"
              data-wow-duration="1s"
              data-wow-delay="0s"
            >
              <div className="section-title">
                <span>Key Product Highlights</span>
                <h2 className="title">An Intelligent Delivery Expert</h2>
              </div>
            </div>
          </Row>
          <Row>
            {this.state.productFeatures?.map((item, index) => (
              <div
                className="col-lg-3 col-md-6 col-sm-6 wow slideInUp"
                data-wow-duration=".5s"
                data-wow-delay="0s"
                key={index}
              >
                <div className="product-inner-item mt-30">
                  {/* <FontAwesomeIcon icon={faLaptopCode} /> */}
                  <span>{item.advantage_sub_title}</span>
                  <h5 className="title">{item.advantage_title}</h5>
                  <div dangerouslySetInnerHTML={{
                    __html: `${item.advantage_description}`,
                  }}></div>
                </div>
                {/* product item */}
              </div>
            ))}
          </Row>
          {/* row */}
        </Container>
        {/* container */}

      </div>
    );
  }
}

export default ProductInnerDetailsArea;