import React from 'react';
import { Link } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap';
import Logo from '../../../assets/images/MIC-Logo.png';
import SocialLink from '../header/SocialLink';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faEnvelope,
  faPhone,
} from '@fortawesome/free-solid-svg-icons';
import { API } from '../../../http/API';


class Footer extends React.Component {

  constructor() {
    super()
    this.state = {
      productList: [],

    }
  }

  componentDidMount() {

    API.get(`/products`)
      .then((response) => {
        if (response.status === 200 || response.status === 201) {
          this.setState({ productList: response.data.data });
        }
      })
      .catch((err) => console.log(err));
  }

  render() {
    return (
      <React.Fragment>
        <footer className="footer-area footer-area-2 footer-area-1 bg_cover" style={{ backgroundImage: 'url(assets/images/footer-bg.jpg)' }}>
          <div className="footer-overlay">
            <Container className="position-relative footer_top">
              <Row>
                <Col lg="5" md="12">
                  <div className="widget-item-1 mt-30">
                    <img src={Logo} alt="" width="150" />
                    <p>The web has changed a lot since Vitaly posted his first article back in 2006. The team at Smashing has changed too, as have the things that we bring to our community onferences, books, and our membership added to the online magazine.</p>
                    <p>One thing that hasn’t changed is that we’re a small team — with most of us not working</p>
                  </div> {/* widget item 1 */}
                </Col>
                <Col lg="4" md="8">
                  <div className="widget-item-2 mt-30">
                    <div className="footer-list">
                      <Row>
                        <Col lg="5" md="6">
                          <h4 className="title">Our Products</h4>
                          <ul>
                            {this.state.productList.map((item, index) => (
                              <li key={index}><Link to={`/products/${item.slug}`}>{item.title}</Link></li>
                            ))}
                          </ul>
                        </Col>
                        <Col lg="7" md="6">
                          <h4 className="title">About</h4>
                          <ul>
                            <li><Link to={"/about"}>About Us</Link></li>
                            <li><Link to={"/privacy-policy"}>Privacy Policy</Link></li>
                            <li><Link to={"/smartsolutions"}>Smart Solution</Link></li>
                            <li><Link to={"/Partner"}>Our Client</Link></li>
                            <li><Link to={"/business-cooperation"}>Business Cooperation</Link></li>
                            <li><Link to={"/news"}>News</Link></li>
                          </ul>
                        </Col>
                      </Row>
                    </div>
                  </div> {/* widget item 2 */}
                </Col>
                <Col lg="3" md="4">
                  <div className="widget-item-2 widget-item-3 mt-30">
                    <h4 className="title">Contact</h4>
                    <ul>
                      <li><a href="tel:+971 528283037">
                        <FontAwesomeIcon icon={faPhone} className="mr-1" />
                        +971 528283037</a></li>
                      <li><a href="mailto:sales@mic-robotics.com" target="__blank">
                        <FontAwesomeIcon icon={faEnvelope} className="mr-1" />
                        sales@mic-robotics.com
                      </a></li>
                      <li>
                        <div className="footer-social">
                          <SocialLink />
                        </div>
                      </li>
                    </ul>
                  </div> {/* widget item 3 */}
                </Col>
              </Row> {/* row */}

            </Container> {/* container */}
            <div className="footer_bottom_text">
              <Container>
                <Row>
                  <Col lg="12">
                    <div className="footer-copyright">
                      <p>Copyright {(new Date().getFullYear())} • All rights reserved by <Link to={"/"}>MIC Robotics Trading</Link> L.L.C • Designed and Managed by <Link to={"https://www.prism-me.com/"}>Prism Digital</Link></p>
                    </div> {/* footer copyright */}
                  </Col>
                </Row> {/* row */}
              </Container>
            </div>
          </div>
        </footer>
        {/*====== BACK TO TOP ======*/}
        <div className="back-to-top back-to-top-2">
          <a href="#backTotop">
            <i className="fas fa-arrow-up" />
          </a>
        </div>
        {/*====== BACK TO TOP ======*/}

      </React.Fragment>
    );
  }
}

export default Footer;