import React from 'react';
import { Link } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap';

class BlogSideBar extends React.Component {
  constructor() {
    super()
    this.state = {
      initialstate: [
        {
          title: "Lorem ipsum dolor sit amet, consecte cing elit, sed do eiusmod tempor.",
          tag: "BUSINESE",
          short_description: "Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incidi-dunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore.",
          inner_rout: "lorem-ipsum-dolor-sit-amet",
          features_img: "assets/images/blog-standard-1.jpg",
        },
        {
          title: "Lorem ipsum dolor sit amet, consecte cing elit, sed do eiusmod tempor.",
          tag: "BUSINESE",
          short_description: "Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incidi-dunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore.",
          inner_rout: "/",
          features_img: "assets/images/blog-standard-3.jpg",
        },
        {
          title: "Lorem ipsum dolor sit amet, consecte cing elit, sed do eiusmod tempor.",
          tag: "BUSINESE",
          short_description: "Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incidi-dunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore.",
          inner_rout: "/",
          features_img: "assets/images/blog-standard-2.jpg",
        },
        {
          title: "Lorem ipsum dolor sit amet, consecte cing elit, sed do eiusmod tempor.",
          tag: "BUSINESE",
          short_description: "Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incidi-dunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore.",
          inner_rout: "/",
          features_img: "assets/images/blog-standard-3.jpg",
        },
        {
          title: "Lorem ipsum dolor sit amet, consecte cing elit, sed do eiusmod tempor.",
          tag: "BUSINESE",
          short_description: "Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incidi-dunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore.",
          inner_rout: "/",
          features_img: "assets/images/blog-standard-2.jpg",
        },
      ],
    }
  }


  render() {
    return (
      <div className="blog-standard-area pt-90 pb-120">
        <Container>
          <Row className="justify-content-center">
            <Col lg="12">
              <div className="blog-standard">
                {this.props.newsData.map((item, index) => (
                  <div className="single-blog-standard mt-30" key={index}>
                    <div className="blog-standard-thumb">
                      <img src={process.env.REACT_APP_IMAGE_BASE_URL + item.featured_image} alt="blog" />
                    </div>
                    <div className="blog-standard-content">
                      {/* <span>{item.tag}</span> */}
                      <h2 className="title">{item.title}</h2>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: `${item.short_description}`,
                        }}
                      ></div>
                      <div className="blog-flex">
                        <div className="blog-right">
                          <Link to={`/news/${item.slug}`}>
                            <i className="fal fa-arrow-right" />
                            Read More
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                ))
                }

              </div>
            </Col>
            {/* <Col lg="4" md="12" sm="8">
              <div className="blog-sidebar-area ml-20">
                <div className="blog-sidebar-item mt-30">
                  <div className="sidebar-title">
                    <h4 className="title">Search Objects</h4>
                  </div>
                  <div className="sidebar-search-item text-center mt-35">
                    <form action="index">
                      <div className="input-box">
                        <input type="text" placeholder="Search your keyword..." />
                        <button>
                          <i className="fal fa-search" />
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
                <div className="blog-sidebar-item mt-30">
                  <div className="sidebar-title">
                    <h4 className="title">Popular Feeds</h4>
                  </div>
                  <div className="sidebar-feeds mt-45">
                    <div className="sidebar-feeds-item mt-30 pl-100">
                      <Link to={"home-1"}>
                        <h4 className="title">
                          Lorem ipsum dolor sit cing elit, sed do.
                        </h4>
                      </Link>
                      <span>
                        <i className="fal fa-calendar-alt" />
                        24th March 2019
                      </span>
                      <img src="assets/images/feeds-1.png" alt="" />
                    </div>
                    <div className="sidebar-feeds-item mt-30 pl-100">
                      <Link to={"home-1"}>
                        <h4 className="title">
                          Lorem ipsum dolor sit cing elit, sed do.
                        </h4>
                      </Link>
                      <span>
                        <i className="fal fa-calendar-alt" />
                        24th March 2019
                      </span>
                      <img src="assets/images/feeds-2.png" alt="" />
                    </div>
                    <div className="sidebar-feeds-item mt-30 pl-100">
                      <Link to={"/home-1"}>
                        <h4 className="title">
                          Lorem ipsum dolor sit cing elit, sed do.
                        </h4>
                      </Link>
                      <span>
                        <i className="fal fa-calendar-alt" />
                        24th March 2019
                      </span>
                      <img src="assets/images/feeds-3.png" alt="" />
                    </div>
                    <div className="sidebar-feeds-item mt-30 pl-100">
                      <Link to={"/home-1"}>
                        <h4 className="title">
                          Lorem ipsum dolor sit cing elit, sed do.
                        </h4>
                      </Link>
                      <span>
                        <i className="fal fa-calendar-alt" />
                        24th March 2019
                      </span>
                      <img src="assets/images/feeds-4.png" alt="" />
                    </div>
                  </div>
                </div>
              </div>
            </Col> */}
          </Row>
        </Container>
      </div>
    );
  }
}

export default BlogSideBar;