import React from 'react';
import Layout from '../../layouts';
import { Container, Row, Col } from 'react-bootstrap';

class TermsAndConditions extends React.Component {
    render() {
        return (
            <Layout>
                <div className="page-title-area" style={{ height: "500px" }}>
                    <Container>
                        <Row>
                            <Col lg="12">
                                <div className="page-title-item text-center">
                                    <h2 className="title" style={{ textAlign: 'center', color: 'white' }}>Terms and Conditions</h2>
                                </div>
                                {/* page title */}
                            </Col>
                        </Row>
                        {/* row */}
                    </Container>
                    {/* container */}
                </div>
                {
                    /*====== PAGE TITLE PART ENDS ======*/
                }
                <div className="pt-105 pb-160">
                    <Container>
                        <Row>
                            <Col lg="12">
                                <div className="content-area">
                                    <p>
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
                                        labore et dolore magna aliqua. Id aliquet lectus proin nibh nisl condimentum id venenatis.
                                        Platea dictumst quisque sagittis purus sit amet volutpat. At urna condimentum mattis
                                        pellentesque id nibh tortor id aliquet. Id aliquet risus feugiat in ante metus dictum at.
                                        Ac turpis egestas sed tempus urna et pharetra pharetra. Eget aliquet nibh praesent tristique
                                        magna sit. Vel turpis nunc eget lorem dolor sed viverra. Vulputate mi sit amet mauris. Consequat
                                        semper viverra nam libero justo laoreet sit amet.
                                    </p>
                                    <br />
                                    <p>
                                        Nulla pellentesque dignissim enim sit amet venenatis. Justo nec ultrices dui sapien eget mi.
                                        Lorem mollis aliquam ut porttitor leo a. Duis convallis convallis tellus id interdum. Nisl
                                        pretium fusce id velit ut tortor pretium. Id porta nibh venenatis cras. Eget duis at tellus at
                                        urna condimentum mattis. Lacus laoreet non curabitur gravida arcu ac. Fermentum iaculis eu non
                                        diam phasellus vestibulum lorem sed risus. Pulvinar neque laoreet suspendisse interdum consectetur
                                        libero id faucibus. Molestie ac feugiat sed lectus vestibulum mattis. Ipsum dolor sit amet
                                        consectetur adipiscing elit. Lacus suspendisse faucibus interdum posuere lorem ipsum.
                                    </p>
                                    <br />
                                    <p>
                                        Habitant morbi tristique senectus et netus et malesuada fames. Libero enim sed faucibus turpis
                                        in eu mi bibendum. Tortor pretium viverra suspendisse potenti nullam ac tortor. Congue nisi
                                        vitae suscipit tellus mauris a diam maecenas sed. Molestie ac feugiat sed lectus vestibulum
                                        mattis ullamcorper velit sed. Laoreet sit amet cursus sit amet dictum sit amet. Phasellus
                                        faucibus scelerisque eleifend donec pretium vulputate sapien nec. Mus mauris vitae ultricies
                                        leo integer. Sed lectus vestibulum mattis ullamcorper velit sed ullamcorper morbi. Libero
                                        volutpat sed cras ornare arcu dui vivamus arcu. Suspendisse sed nisi lacus sed viverra. Vivamus
                                        at augue eget arcu dictum. Est velit egestas dui id ornare. Donec pretium vulputate sapien nec
                                        sagittis aliquam malesuada. Pretium nibh ipsum consequat nisl vel pretium lectus quam. Orci ac
                                        auctor augue mauris.
                                    </p>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </Layout>
        );
    }
}

export default TermsAndConditions;